import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const getCompanyData = () => {
    const data = localStorage.getItem("companyData");
    try {
      return JSON.parse(data) || [];
    } catch (e) {
      console.error("Invalid JSON in localStorage for companyData");
      return [];
    }
  };

  const companyData = getCompanyData();
  const [estateCode, setEstateCode] = useState(
    localStorage.getItem("estateCode")
  );
  const [themeColor, setThemeColor] = useState("#4A6AFE");

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "estateCode") {
        setEstateCode(event.newValue);
      }
    };

    const handleLocalChange = () => {
      const newEstateCode = localStorage.getItem("estateCode");
      setEstateCode(newEstateCode);
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageChange", handleLocalChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localStorageChange", handleLocalChange);
    };
  }, []);

  useEffect(() => {
    const estateName = getEstateName(estateCode);
    const newThemeColor =
      estateName === "Moksha"
        ? "#060807"
        : estateName === "Capella - Altair Boutique Hotel"
        ? "#D4A95A"
        : estateName === "Calico Bar And Eatery"
        ? "#1E5A53"
        : "#1E5A53"

    setThemeColor(newThemeColor);
  }, [estateCode]);

  const getEstateName = (estateCode) => {
    if (!companyData || !companyData.length) return null;
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCode
    );
    return estate ? estate.estate_name : null;
  };

  return (
    <ThemeContext.Provider value={{ themeColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export const setEstateCodeForTheme = (newEstateCode) => {
  localStorage.setItem("estateCode", newEstateCode);
  window.dispatchEvent(new Event("localStorageChange"));
};
