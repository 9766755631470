import React, { useEffect, useState } from "react";
import "../Styles/Navbar.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { Popover } from "@headlessui/react";
import axios from "axios";

const solutions = [
  {
    name: "Sign up",
    href: "/signin",
  },
  {
    name: "Log in",
    href: "/signin",
  },
];

const solutionsTwo = [
  {
    name: "Account",
    href: "/account",
  },
  {
    name: "Bookings",
    href: "/bookingHistory",
  },
  {
    name: "Log out",
    href: "",
  },
];

export const Navbar4 = ({ cmpcode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [navbarText, setNavbarText] = useState("");
  const [navUrl, setNavUrl] = useState("/location");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState("");
  const [navbarColor, setNavbarColor] = useState("");

  const location = useLocation();

  const [estateName, setEstateName] = useState("");
  const { estatecode } = useParams();
  const [storedEstateCode, setStoredEstateCode] = useState(() => {
    return localStorage.getItem('estatecode') || null;
  });

  useEffect(() => {
    if (estatecode) {
      localStorage.setItem('estatecode', estatecode);
      setStoredEstateCode(estatecode);
    }
  }, [estatecode]);
  const currentEstateCode = estatecode || storedEstateCode;


  useEffect(() => {
    const checkLoginStatus = async () => {
        try {
          
            const loggedInCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('loggedIn='));
            const isLoggedInCookie = loggedInCookie ? loggedInCookie.split('=')[1] === 'true' : false;

         
            if (isLoggedInCookie) {
                const response = await axios.get('https://server.testgrouple.com/api/v1/enduser/profile/viewProfile');
                if (response.status === 200 && response.data.data[0]) {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false); 
                    document.cookie = "loggedIn=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                }
            } else {
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.error('Error checking login status:', error);
            setIsLoggedIn(false);
        }
    };

    checkLoginStatus();
}, []);

  const fetchOutlets = async () => {
    const hostname = window.location.hostname;
    setIsLoading(true);
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl = `https://server.testgrouple.com/api/v1/enduser/estate/getEstateList/${cmpcode}`;
    } else if (hostname.includes("grouple.tech")) {
      // apiUrl = `https://production.grouple.tech/api/v1/enduser/comapny/merchantInformation/${companyName}`;
    }

    try {
      const response = await axios.get(apiUrl);
      if (response.status === 200 && response.data && response.data.data) {
        setNavbarLogo(response.data.data[0].company_logo);
        setNavbarColor(response.data.data[0].company_navbar);
      } else {
        throw new Error("Failed to fetch outlet information");
      }
    } catch (error) {
      console.error("Error fetching outlets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOutletDetail = async () => {
    const hostname = window.location.hostname;
    setIsLoading(true);
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl = `https://server.testgrouple.com/api/v1/enduser/estate/getEstateDetails`;
    } else if (hostname.includes("grouple.tech")) {
      // apiUrl = `https://production.grouple.tech/api/v1/enduser/comapny/getOutlettInformation/${outletCode}`;
    }

    const apiCodes = {
      cmpcode: cmpcode,
      estatecode: currentEstateCode,
    };

    try {
      const response = await axios.post(apiUrl, apiCodes);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data.length > 0
      ) {
        const estateData = response.data.data[0];
        const estateAttrs = estateData.estate_attrs;
        setEstateName(response.data.data[0].estate_name);

        if (Array.isArray(estateAttrs)) {
          const navbarColorAttr = estateAttrs.find(
            (attr) => attr.estate_attr_name === "navbar_color"
          );

          if (navbarColorAttr) {
            setNavbarColor(navbarColorAttr.estate_attr_description);
          } else {
            console.warn("Navbar color attribute not found");
          }

          setNavbarLogo(estateData.logo);
        } else {
          throw new Error("estate_attrs is not an array or is undefined");
        }
      } else {
        throw new Error(
          "Failed to fetch outlets or unexpected response structure"
        );
      }
    } catch (error) {
      console.error("Error fetching outlets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname === "/location") {
      fetchOutlets();
    } else {
      fetchOutletDetail();
    }
  }, []);

  useEffect(() => {
    const updateNavbarText = () => {
      const pathnameToNavbarText = {
        "/location": "Outlets",
        "/package/": estateName || "",
        "/groupSize/": "Experiences",
        "/personalInfo/foodmenu": "Food and beverage",
        "/personalInfo/": "Experiences",
        "/datemobile/": "Book a reservation",
        "/personalGuestSize": "Book a reservation",
        "/FandB/": "Food and beverage",
        "/groupSummary": "Confirm and reserve",
        "/groupRes/": "Personal details",
        "/PersonalRes": "Personal details",
        "/account": "Account Information",
        "/profile": "Personal Information",
      };
    
      const matchedPath = Object.keys(pathnameToNavbarText).find(path => location.pathname.startsWith(path));
      setNavbarText(pathnameToNavbarText[matchedPath] || "");
    };
    

    updateNavbarText();
  }, [location.pathname, estateName]);


  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('https://server.testgrouple.com/api/v1/enduser/profile/logout');
      
      if (response.status === 202) {
        localStorage.removeItem("estateCode");
        setIsLoggedIn(false);
        document.cookie = "loggedIn=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = "/location";
      }
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleBackClick = () => {
    const currentPath = window.location.pathname;
    setIsLoading(true);
    const redirectToPackageInfoPaths = ["/summary", "/DateAndTime"];
    if (redirectToPackageInfoPaths.includes(currentPath)) {
      window.location.href = "/package";
      setIsLoading(false);
    } else {
      window.history.back();
      setIsLoading(false);
    }
  };

  const [backgroundColor, setBackgroundColor] = useState(navbarColor);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isSmall = window.innerWidth < 640;
      setBackgroundColor(isSmall ? "white" : navbarColor);
      setIsSmallScreen(isSmall);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navbarColor]);

  const imgpath = "/Imagess/Calico/calicoLogo.png";

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
        </div>
      ) : (
        <nav
          className="navbar-div2"
          style={{ display: "flex", backgroundColor: backgroundColor }}
        >
          <div style={{ width: "10%" }} className="backParent">
            <button
              onClick={handleBackClick}
              style={{
                fontSize: "1rem",
                textDecoration: "underline",
                fontWeight: "400",
                fontFamily: "Gilroy-Medium",
                color: "white",
              }}
            >
              Back
            </button>
          </div>
          <div className="backArrowDiv">
            <button
              onClick={handleBackClick}
              style={{
                fontWeight: "400",
                fontFamily: "Gilroy-Medium",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 12H20"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 18L4 12L10 6"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="MobileTExtParent" style={{ display: "flex" }}>
            <Link to={navUrl} style={{ textDecoration: "none" }}>
              <img
                src={imgpath}
                alt="logo"
                className="barbaar-logo2"
                style={{ width: "8rem", height: "4.375rem" }}
              />
            </Link>
            <div className="NavbarText">{navbarText}</div>
          </div>
          <Popover>
            <Popover.Button className="NavbarHam2">
              {isSmallScreen ? (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 18L19 18"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 12L19 12"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 6L19 6"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              ) : (
                <>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 18L19 18"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 12L19 12"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 6L19 6"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.8245 10.3138C10.7915 10.3708 10.7442 10.4181 10.6872 10.4511C10.6301 10.484 10.5655 10.5013 10.4996 10.5013H1.49962C1.43382 10.5012 1.3692 10.4838 1.31225 10.4509C1.2553 10.4179 1.20801 10.3706 1.17514 10.3136C1.14228 10.2566 1.12498 10.192 1.125 10.1262C1.12502 10.0604 1.14234 9.99575 1.17524 9.93878C1.88915 8.70456 2.9893 7.81956 4.27321 7.40003C3.63813 7.02196 3.14472 6.44587 2.86873 5.76024C2.59275 5.07461 2.54947 4.31734 2.74553 3.60472C2.94158 2.89211 3.36614 2.26355 3.954 1.81558C4.54186 1.36761 5.26052 1.125 5.99962 1.125C6.73871 1.125 7.45737 1.36761 8.04523 1.81558C8.63309 2.26355 9.05765 2.89211 9.25371 3.60472C9.44976 4.31734 9.40648 5.07461 9.1305 5.76024C8.85452 6.44587 8.3611 7.02196 7.72602 7.40003C9.00993 7.81956 10.1101 8.70456 10.824 9.93878C10.857 9.99574 10.8744 10.0604 10.8745 10.1262C10.8745 10.192 10.8573 10.2567 10.8245 10.3138Z"
                        fill="#1E5A53"
                      />
                    </svg>
                  </div>
                </>
              )}
            </Popover.Button>

            <Popover.Panel
              transition
              className={`${
                isSmallScreen
                  ? "absolute top-full right-0 w-full max-w-[11rem] flex flex-col overflow-hidden rounded-md bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
                  : "absolute right-[-1rem] z-10 mt-2 flex w-screen max-w-max -translate-x-1/2 px-4 py-3 transition rounded-md bg-white data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              }`}
            >
              <div className="flex flex-col">
                {isLoggedIn
                  ? solutionsTwo.map((item) => (
                      <div
                        key={item.name}
                        className="group relative py-2 flex rounded-lg hover:bg-gray-50"
                        onClick={
                          item.name === "Log out" ? handleLogout : undefined
                        }
                      >
                        <div>
                          <a
                            href={item.href}
                            className="no-underline px-4 text-black text-base font-normal font-['Gilroy-Medium']"
                          >
                            {item.name}
                          </a>
                        </div>
                      </div>
                    ))
                  : solutions.map((item) => (
                      <div
                        key={item.name}
                        className="group relative py-2 flex rounded-lg hover:bg-gray-50"
                        onClick={
                          item.name === "Log out" ? handleLogout : undefined
                        }
                      >
                        <div>
                          <a
                            href={item.href}
                            className="no-underline px-4 text-black text-base font-normal font-['Gilroy-Medium']"
                          >
                            {item.name}
                          </a>
                        </div>
                      </div>
                    ))}
              </div>
            </Popover.Panel>
          </Popover>
        </nav>
      )}
    </>
  );
};
