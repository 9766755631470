import React, { useEffect, useState } from "react";
import "../Styles/Package.css";
import PackageInfo from "../Components/PackageInfo";
import { Navbar4 } from "../Components/NavbarFour";

const Package = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const estateCode = localStorage.getItem("estateCode");
  const [estateName, setEstateName] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCompanyData = () => {
    const data = localStorage.getItem("companyData");
    try {
      return JSON.parse(data) || [];
    } catch (e) {
      console.error("Invalid JSON in localStorage for companyData");
      return [];
    }
  };
  const companyData = getCompanyData();

  useEffect(() => {
    const estate = getEstateName(estateCode);
    setEstateName(estate);
  }, [estateCode, companyData]);

  const getEstateName = (estateCode) => {
    if (!companyData || !companyData.length) return null;
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCode
    );
    return estate ? estate.estate_name : null;
  };

  return (
    <>
      <Navbar4 />
      <PackageInfo />
    </>
  );
};

export default Package;
