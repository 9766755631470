import React, { useState, useEffect } from "react";
import logo from "../Images/grouplelogo.png";
import "../Styles/SignIn.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useReferrer } from "./RefrrerContext";

export default function SignIn() {
  const [data, setData] = useState({
    phoneNumber: "",
    dialCode: "",
  });
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { setReferrer } = useReferrer();

  useEffect(() => {
    const referrerUrl = document.referrer || '/location';
    setReferrer(referrerUrl);
  }, [setReferrer]);


  const handleGetOTP = async (e) => {
    e.preventDefault();
    const countryCode = data.dialCode.split("+");
    const phoneNumberWithoutDialCode = data.phoneNumber.replace(
      countryCode[1],
      ""
    );

    try {
      const response = await axios.post(
        "https://server.testgrouple.com/api/v1/enduser/profile/getOTP",
        {
          customer_mob: phoneNumberWithoutDialCode,
          user_internationalcode: data.dialCode,
        }
      );

      if (response.status === 200) {
        console.log("Successfully sent OTP:", response);
        const phoneNoAndDailCode = {
          dialCode: data.dialCode,
          phone: phoneNumberWithoutDialCode,
        };
        navigate("/otp", { state: {phoneNoAndDailCode} });
      } else {
        console.error("Error in response:", response);
        return null;
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      return null;
    }
  };

  const handlePhoneNumberChange = (value, country) => {
    const isValid = value.length >= 10;
    setData({
      phoneNumber: value || "",
      dialCode: country ? `+${country.dialCode}` : "",
    });
    setIsPhoneNumberValid(isValid);
  };

  useEffect(() => {
    if (!location.state?.from) {
      navigate(location.pathname, { state: { from: document.referrer } });
    }
  }, [location, navigate]);

  return (
    <div className="bg-[#F8F8FF] flex min-h-[100vh] flex-1 flex-col justify-center align-middle">
      <div>
        <img className="mx-auto h-14 w-auto" src={logo} alt="grouple-logo" />
      </div>

      <div className="mt-14 sm:mx-auto">
        <div className="signInCard">
          <h2 className="signInText">Log in or sign up</h2>

          <form>
            <div>
              <label htmlFor="phone" className="block enterText">
                Enter your mobile number
              </label>
              <div className="mt-2">
                <PhoneInput
                  country={"gb"}
                  inputStyle={{
                    width: "100%",
                    height: "3rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #AEAEB2",
                    fontSize: "1.125rem",
                    fontFamily: "Gilroy-Regular",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                  dropdownStyle={{
                    padding: "1rem",
                    height: "80rem",
                    width: "24rem",
                    borderRadius: "0.25rem",
                  }}
                  buttonStyle={{
                    marginTop: "0.3rem",
                    marginBottom: "0.3rem",
                    borderRight: "2px solid #293897",
                    background: "#fff",
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                  name="phoneNumber"
                  placeholder="Mobile number"
                  value={data.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  maxLength={15}
                />
              </div>
            </div>

            <div>
              <button
                onClick={handleGetOTP}
                type="submit"
                className="contBttnSignIn"
                style={{
                  backgroundColor: isPhoneNumberValid ? "#4a6afe" : "#d8d8dc",
                }}
              >
                Continue
              </button>
            </div>

            <h3 className="belowSignIn">
              By continuing, you agree to Calico’s{" "}
              <Link to="https://www.grouple.in/privacy" target="_blank">
              <span>Terms of Use and Privacy Policy.</span>
              </Link>
            
            </h3>

            {/* <h3>Need help?</h3> */}
          </form>
        </div>
      </div>
    </div>
  );
}
