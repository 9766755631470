import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../Styles/TimeSlotModal.css";

const TimeSlotModal = ({
  isOpen,
  onClose,
  setSelectedTime,
  handleSaveTime,
  setDisplayedTime,
  isFixedDate,
  packageData
}) => {
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    if (isOpen && isFixedDate !== 1) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen, isFixedDate]);


const getCurrentDay = () => {
  return new Date()
    .toLocaleDateString("en-US", { weekday: "short" })
    .toLowerCase();
};

const currentDay = getCurrentDay();

  useEffect(() => {
    if (isFixedDate === 1) {

      const fixedSlot = packageData?.package_time_slots[0];
      const fixedSlotValue = `${fixedSlot.package_min_time_slot} - ${fixedSlot.package_max_time_slot}`;
      const fixedSlotLabel = `${new Date(
        `1970-01-01T${fixedSlot.package_min_time_slot}`
      ).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })} - ${new Date(
        `1970-01-01T${fixedSlot.package_max_time_slot}`
      ).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`;

      setSelectedTime(fixedSlotValue);
      setDisplayedTime(fixedSlotLabel);
      return; 
    }

    const filteredTimeSlots = packageData?.package_time_slots
      ? packageData.package_time_slots
          .filter((slot) => slot.package_day === currentDay)
          .map((slot) => ({
            value: `${slot.package_min_time_slot} - ${slot.package_max_time_slot}`,
            label: `${new Date(
              `1970-01-01T${slot.package_min_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} - ${new Date(
              `1970-01-01T${slot.package_max_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}`,
          }))
      : [];

    setTimeSlots(filteredTimeSlots);
  }, [
    isFixedDate,
    packageData?.fixed_min_date,
    packageData?.package_time_slots,
    currentDay,
    setSelectedTime,
    setDisplayedTime,
  ]);

  if (!isOpen || isFixedDate === 1) return null; 

  const handleTimeSlotClick = (slot) => {
    setSelectedTime(slot.value);
    setDisplayedTime(slot.label);
    handleSaveTime();
    onClose();
  };



  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="pr-5 py-4 border-b border-gray-200 justify-between flex">
          <div className="w-11/12 text-center text-black text-xl font-normal font-['Gilroy-SemiBold']">
            Time slot
          </div>
          <div className="w-6 h-6 relative" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 7L17 17"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 17L17 7"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="time-slots">
          {timeSlots.map((slot, index) => (
            <div
              className="timeBoxLabel"
              key={index}
              onClick={() => handleTimeSlotClick(slot)}
            >
              <div className="timeLabel">
                {slot.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default TimeSlotModal;



