import React, { useEffect, useLayoutEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/Carousel.css";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import Navbar from "../Components/Navbar";
import Body from "../Components/Body";
import Footer from "../Components/Footer";
import TextCarousel from "../Components/TextCarousel";
import Questions from "../Components/Questions";
import MobileScroll from "../Components/MobileScroll/Index";
import axios from "axios";
import CarauselForMobile from "../Components/MobileScrollTwo/CarauselForMobile";

import event1 from "../Images/event1.png"
import event2 from "../Images/event2.png"
import event3 from "../Images/event3.png"

import mobile1 from "../Images/mobileevent.png"
import mobile2 from "../Images/mobileevent2.png"
import mobile3 from "../Images/mobileevent3.png"

const HomePageThree = () => {
  const [companyData, setCompanyData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const currentUrl = window.location.href;
    const path = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);
    fetchCompanyData(path);
  }, []);


  useEffect(() => {
    localStorage.setItem("companyData", JSON.stringify(companyData));
  }, [companyData]);


  useLayoutEffect(() => {
    setIsMobile(window.innerWidth <= 640);


    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const fetchCompanyData = async (companyName) => {
    const hostname = window.location.hostname;
    let apiUrl;
    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl = `https://server.testgrouple.com/api/v1/enduser/comapny/merchantInformation/${companyName}`;
    }
    if (hostname.includes("grouple.tech")) {
      apiUrl = `https://production.grouple.tech/api/v1/enduser/comapny/merchantInformation/${companyName}`;
    }
    try {
      let response = await axios.get(apiUrl);
      setCompanyData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  return loading ? (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full border-t-4 border-primary h-12 w-12"></div>
    </div>
  ) : (
    <div>
      <Navbar />
      <>
      <Carousel
        className="carouselStyle"
        prevIcon={
          <div
            style={{
              padding: "1rem",
              borderRadius: "6.5rem",
              background: "rgba(0, 0, 0, 0.70)",
            }}
          >
            <FaArrowLeftLong
              style={{
                width: "1.5rem",
                color: "white",
                height: "1.5rem",
                
              }}
            />
          </div>
        }
        nextIcon={
          <div
          style={{
            padding: "1rem",
            borderRadius: "6.5rem",
            background: "rgba(0, 0, 0, 0.70)",
          }}
        >
          <FaArrowRightLong
            style={{
              width: "1.5rem",
              color: "white",
              height: "1.5rem",
              
            }}
          />
        </div>
        }
      >
         <Carousel.Item interval={1000} className="caruselItem">
          <img src={event1} alt="First slide" className="imageStyle" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem">
          <img src={event2} alt="First slide" className="imageStyle" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem">
          <img src={event3} alt="Second slide" className="imageStyle" />
        </Carousel.Item>
      </Carousel>

      {/* *********Mobile Version******** */}

      <Carousel className="carouselStyle2">
        <Carousel.Item interval={1000} className="caruselItem2">
          <img src={mobile1} alt="First slide" className="imageStyle2" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem2">
          <img src={mobile2} alt="Second slide" className="imageStyle2" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem2">
          <img src={mobile3} alt="Third slide" className="imageStyle2" />
        </Carousel.Item>
    
      </Carousel>
      <Link to="/location">
        <button className="reserveNowCarousel" style={{background:"#4A6AFE"}}>Reserve Now</button>
      </Link>
    </>
      <TextCarousel />
      {isMobile ? <CarauselForMobile /> : <MobileScroll />}
      <Body />
      <Questions />
      <Footer />
    </div>
  );
};


export default HomePageThree;





