import React, { useState, useEffect } from "react";
import "../Styles/Package.css";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const Rules = ({ packageData }) => {
  const [isReqPopupOpen, setIsReqPopupOpen] = useState(false);
  const [isRulePopupOpen, setIsRulePopupOpen] = useState(false);


  const openPopupReq = () => {
    setIsReqPopupOpen(true);
  };
  const openPopupRule = () => {
    setIsRulePopupOpen(true);
  };

  const closePopupReq = () => {
    setIsReqPopupOpen(false);
  };
  const closePopupRule = () => {
    setIsRulePopupOpen(false);
  };

  useEffect(() => {
    if (isReqPopupOpen || isRulePopupOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isReqPopupOpen, isRulePopupOpen]);

  const requirements = packageData?.package_attr?.filter(
    (attr) => attr.package_attr_category === "things_to_know"
  ) || [];

  const rules = packageData?.package_attr?.filter(
    (attr) => attr.package_attr_category === "rules"
  ) || [];

  const renderItem = (item) => (
    <div className="div-1" key={item.package_attr_name}>
      <div>
      {item.package_attr_img ? (
          <div
            dangerouslySetInnerHTML={{ __html: item.package_attr_img }}
            style={{ width: "20px", height: "20px" }}
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            {/* Placeholder SVG path */}
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2L2 22h20L12 2z" fill="currentColor" />
          </svg>
        )}
      </div>
      <div>{item.package_attr_description}</div>
    </div>
  );

  return (
    <>
      <div className="RulesForDesktop">
        <div className="things">
          <h2>Things to know</h2>

          <div className="outer-flex-div">
            <div className="requirements">
              <h2>Requirements</h2>
              {requirements.map(renderItem)}
            </div>
            <div className="rules">
              <h2>Rules </h2>
              {rules.map(renderItem)}
              
            </div>
          </div>
        </div>
      </div>

      <div className="RulesForPhone">
        <div className="ReqPhone" onClick={openPopupReq}>
          <div>
            <h3>Requirement</h3>
            <p>
              You’ll need a photo that matches the one on your ID to confirm
              who’s actually going on this experience.
            </p>
          </div>
          <div className="arrow-icon">
            <MdOutlineArrowForwardIos />
          </div>
        </div>
        <div className="RulePhone" onClick={openPopupRule}>
          <div>
            <h3>Rules</h3>
            <p>The charges will be based on Per Person basis.</p>
          </div>
          <div className="arrow-icon">
            <MdOutlineArrowForwardIos />
          </div>
        </div>

        {isReqPopupOpen && (
          <div className="Req-popup-background-custom">
            <div className="Req-popup-custom">
              <button className="ReqPopupCloseBtn" onClick={closePopupReq}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 7L17 17"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 17L17 7"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>

              <div className="requirements">
              <h2>Requirements</h2>
              {requirements.map(renderItem)}
            </div>
            </div>
          </div>
        )}
        {isRulePopupOpen && (
          <div className="Req-popup-background-custom">
            <div className="Req-popup-custom">
              <button className="ReqPopupCloseBtn" onClick={closePopupRule}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 7L17 17"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 17L17 7"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div className="rules">
              <h2>Rules</h2>
              {rules.map(renderItem)}
            </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Rules;
