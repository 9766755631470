// ReferrerContext.js
import React, { createContext, useState, useContext } from 'react';

const ReferrerContext = createContext();

export const ReferrerProvider = ({ children }) => {
  const [referrer, setReferrer] = useState('/');

  return (
    <ReferrerContext.Provider value={{ referrer, setReferrer }}>
      {children}
    </ReferrerContext.Provider>
  );
};

export const useReferrer = () => useContext(ReferrerContext);
