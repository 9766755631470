import { Navbar2 } from "./NavbarTwo";
import Footer from "./Footer";
import "../Styles/Account.css";
import { BiLockOpen } from "react-icons/bi";
import { SlWallet } from "react-icons/sl";
import { LuSettings2 } from "react-icons/lu";
import { IoPersonOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Navbar4 } from "./NavbarFour";
import React, { useEffect, useState } from "react";
const features = [
  {
    name: "Personal Details",
    description:
      "Keep your personal details up-to-date for a seamless experience.",
    icon: IoPersonOutline,
  },
  {
    name: "Login & Security",
    description: "Enhance your account's safety by updating security settings.",
    icon: BiLockOpen,
  },
  {
    name: "Payments",
    description: "Review your payments for easier and faster booking.",
    icon: SlWallet,
  },
  {
    name: "Preference",
    description: "Enhance your account's safety by updating security settings.",
    icon: LuSettings2,
  },
];

export default function Account() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/profile");
  };
  return (
    <>
      <Navbar4 />
      <div className="bg-white min-h-[72vh] pb-6">
        <main className="isolate">
          <div className="mx-auto mt-10 max-w-7xl px-6 lg:px-8">
            <div className="max-w-2xl lg:text-start">
              <p className="accountHeading">Account Information</p>
              <p className="acccountSubHeading">
                Manage your reservation experience
              </p>
            </div>
            <div className="sm:mt-10 lg:max-w-7xl">
              <dl
                className="grid max-w-xl grid-cols-1 gap-x-6 gap-y-6 lg:max-w-none lg:grid-cols-2 cursor-pointer "
                onClick={handleClick}
              >
                {features.map((feature) => (
                  <div key={feature.name} className="relative accountBox">
                    <dt className="flex flex-col items-start">
                      <div className="flex h-16 w-16 items-center justify-center rounded-full bg-[#E0E0E0]">
                        <feature.icon
                          className="h-6 w-6 text-black"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="accountName">{feature.name}</p>
                    </dt>
                    <dd className="accountDesc">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </main>
      </div>
      {windowWidth >= 1024 && <Footer />}
    </>
  );
}
