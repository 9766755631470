import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import "../Styles/BookingHistory.css";
import rectangle from "../Images/ReservationImage1.png";
import { Navbar2 } from "./NavbarTwo";
import axios from "axios";
import { Navbar4 } from "./NavbarFour";

const AllPastReservations = () => {
  const [pastReservations, setPastReservations] = useState([]);
  const [error, setError] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBookingHistory = async () => {
      try {
        const response = await axios.post(
          "https://server.testgrouple.com/api/v1/enduser/profile/getReservationHistory"
        );
        if (response.status === 200) {
          const bookingHistory = response.data.data;
          const today = new Date();
          const pastRes = bookingHistory.filter(
            (booking) => new Date(booking.bookingDate) < today
          );
          setPastReservations(pastRes);
        } else {
          setError(`Unexpected response code: ${response.status}`);
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching booking history:", error);
      }
    };

    fetchBookingHistory();
  }, []);

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hoursInt = parseInt(hours);
    const minutesInt = parseInt(minutes);
    const period = hoursInt >= 12 ? "PM" : "AM";
    const formattedHours = hoursInt % 12 || 12;
    return `${formattedHours}:${
      minutesInt < 10 ? "0" : ""
    }${minutesInt} ${period}`;
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return { text: "Pending", className: "text-yellow-500" };
      case 1:
        return { text: "Confirmed", className: "text-green-500" };
      case 2:
        return { text: "Cancelled", className: "text-red-500" };
      default:
        return { text: "Unknown", className: "text-gray-500" };
    }
  };

  return (
    <div>
      {windowWidth >= 1024 ? <Navbar4 /> : <Navbar2 />}

      <div className="BookingHistoryWrapper">
        <h1 className="BookingsHeading">Bookings</h1>

        <div className="PastResWrapper">
          <h1 className="PastResHeading">Past Reservations</h1>
          {pastReservations.length > 0 ? (
            pastReservations.map((reservation, index) => (
              <div className="PastResChild" key={index}>
                <img className="UpcomingresImgOne" src={rectangle} alt="" />
                <div className="PastResInner">
                  <div className="PastSummaryWrapper">
                    <div className="PastSummaryChild">
                      <div
                        className="PastSumaryAdress"
                        style={{ alignItems: "flex-start" }}
                      >
                        <div className="text-neutral-800 text-lg font-normal font-['Gilroy-SemiBold'] outletName">
                          {reservation.bookingOutlet}
                        </div>
                        <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] outletAddress">
                          {reservation.bookingOutletAddress}
                        </div>
                      </div>
                    </div>
                    <div className="justify-start items-center gap-[22px] inline-flex">
                      <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] resDate">
                        {new Date(reservation.bookingDate).toLocaleDateString()}
                      </div>
                      <div className="w-2 h-2 bg-neutral-700 rounded-full" />
                      <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] resTime">
                        {formatTime(reservation.bookingSlotStartTime)} -{" "}
                        {formatTime(reservation.bookingSlotEndTime)}
                      </div>
                    </div>
                    <div
                      className={`${
                        getStatus(reservation.record_status).className
                      } text-base font-normal font-['Gilroy-SemiBold'] resStatus`}
                    >
                      {getStatus(reservation.record_status).text}
                    </div>
                  </div>
                  <div className="text-groupleTheme text-[22px] font-normal font-['Gilroy-SemiBold'] resCode">
                    #{reservation.reservation_code}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No past reservations.</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllPastReservations;
