import React, { useState, useRef, useEffect } from "react";
import logo from "../Images/grouplelogo.png";
import "../Styles/SignIn.css";
import { Row, Col, Input } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import Cookies from "js-cookie";
import { useReferrer } from "./RefrrerContext";

export default function Otp() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(30);
  const [timerRunning, setTimerRunning] = useState(true);
  const [isTimerFinished, setIsTimerFinished] = useState(false);
  const [otpFilled, setOtpFilled] = useState(false);
  const inputRefs = useRef([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { referrer } = useReferrer();


  const phoneNoAndDailCode = location.state?.phoneNoAndDailCode || {};

  useEffect(() => {
    let countdown;
    if (timerRunning) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            setTimerRunning(false);
            setIsTimerFinished(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [timerRunning]);

  useEffect(() => {
    const isFilled = otp.every((digit) => digit !== "");
    setOtpFilled(isFilled);

    if (timer === 30) {
      setOtpFilled(false);
    }
  }, [otp, timer]);

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (/^\d$/.test(value) && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      }
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };



  const handleResend = () => {
    setOtp(["", "", "", "", "", ""]);
    inputRefs.current[0].focus();
    setTimer(30);
    setTimerRunning(true);
    setIsTimerFinished(false);
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        customer_mob: phoneNoAndDailCode.phone,
        user_internationalcode: phoneNoAndDailCode.dialCode,
        otp: otp.join(""),
      };

      console.log(payload);
      const response = await axios.post(
        "https://server.testgrouple.com/api/v1/enduser/profile/verifyOTP/development",
        payload,
        {
          withCredentials: true,
        }
      );
      if (response.status === 202) {
        document.cookie = "loggedIn=true; path=/";
        if (response.data.data.email === "") {
          navigate("/create", { state: { from: referrer } });
        } else {
          window.location.href = referrer;
          // navigate("/location")
        }
      } else {
        console.error("Error in response:", response);
        return null;
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      return null;
    }
  };

  const [inputStyle, setInputStyle] = useState({});
  const largeScreenStyle = {
    width: "3.2rem",
    height: "3.2rem",
    marginRight: "0.8rem",
    textAlign: "center",
    fontSize: "1.5rem",
    border: "1px solid #242426",
    borderRadius: "0.5rem",
  };

  const smallScreenStyle = {
    width: "2.6rem",
    height: "2.6rem",
    marginRight: "0.5rem",
    textAlign: "center",
    fontSize: "1rem",
    border: "1px solid #242426",
    borderRadius: "0.5rem",
  };

  useEffect(() => {
    const updateStyle = () => {
      if (window.innerWidth <= 600) {
        setInputStyle(smallScreenStyle);
      } else {
        setInputStyle(largeScreenStyle);
      }
    };

    updateStyle();

    window.addEventListener("resize", updateStyle);

    return () => {
      window.removeEventListener("resize", updateStyle);
    };
  }, []);

  return (
    <>
      <div className="bg-[#F8F8FF] flex min-h-[100vh] flex-1 flex-col justify-center align-middle">
        <div>
          <img className="mx-auto h-14 w-auto" src={logo} alt="grouple-logo" />
        </div>

        <div className="mt-14 sm:mx-auto">
          <div className="signInCard">
            <h2 className="signInText">OTP Verification</h2>

            <form method="POST">
              <div>
                <label htmlFor="otp" className="block enterText">
                  Enter the OTP which we’ve sent to <br />
                  <span>
                    {phoneNoAndDailCode.dialCode} {phoneNoAndDailCode.phone}.
                  </span>
                  <Link
                    to="/signin"
                    style={{ textDecoration: "none", paddingLeft: "1rem" }}
                  >
                    <span>Change</span>
                  </Link>
                  <span className="timer">
                    {`00:${timer < 10 ? `0${timer}` : timer}`}
                  </span>
                </label>
                <div className="mt-4">
                  <Row justify="center">
                    {otp.map((digit, index) => (
                      <Col key={index}>
                        <Input
                          maxLength={1}
                          value={digit}
                          onChange={(e) =>
                            handleOtpChange(e.target.value, index)
                          }
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          style={inputStyle}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className={`contBttnSignIn ${
                    otpFilled ? "contBttnSignInActive" : ""
                  }`}
                  onClick={handleVerifyOTP}
                >
                  Verify
                </button>
              </div>

              {/* <h3 className="belowSignIn">
                By continuing, you agree to Barbaar’s{" "}
                <span>Terms of Use and Privacy Policy.</span>
              </h3> */}

              <h3 className="otpResend">
                Didn’t receive OTP?{" "}
                <span
                  onClick={isTimerFinished ? handleResend : null}
                  style={{
                    cursor: isTimerFinished ? "pointer" : "not-allowed",
                    color: isTimerFinished ? "#4a6afe" : "#6c6c70",
                  }}
                >
                  Resend
                </span>
              </h3>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
