import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer";
import DateAndTime from "../Components/DateAndTime";
import { Navbar4 } from "../Components/NavbarFour";


const RulesAndTime = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div>
      <Navbar4 />
      <DateAndTime />
      <div className={isMobile ? "footer-hidden" : ""}>
        <Footer />
      </div>
    </div>
  );
};


export default RulesAndTime;
