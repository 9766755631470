import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Styles/DateModal.css";

const customNextIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M9 18L15 12L9 6"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const customPrevIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="24"
    viewBox="0 0 44 24"
    fill="none"
  >
    <path
      d="M15 6L9 12L15 18"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DateModal = ({
  isOpen,
  onClose,
  selectedDate,
  setSelectedDate,
  handleSaveDate,
  minDate,
  fixedMinDate,
  isFixedDate,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  useEffect(() => {
    if (isFixedDate === 1) {
      const fixedDate = new Date(fixedMinDate);
      setSelectedDate(fixedDate);
    }
  }, [isFixedDate, fixedMinDate, setSelectedDate]);

  if (!isOpen || isFixedDate === 1) return null;

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleSaveDate();
  };

  const blockedDays = [1,2]



  return ReactDOM.createPortal(
    <div className="modal-overlay2">
      <div className="modal-content2">
        <div className=" py-4 border-b border-gray-200 justify-between flex">
          <div className="w-11/12 text-center text-black text-xl font-normal font-['Gilroy-SemiBold']">
            Date
          </div>
          <div className="w-7 h-7 relative" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 7L17 17"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 17L17 7"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="datePickerForMobile">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
            dateFormat="dd-MM-yyyy"
            minDate={minDate}
            nextMonthButtonLabel={customNextIcon}
            previousMonthButtonLabel={customPrevIcon}
            filterDate={(date) => {
              const day = date.getDay();
              return !blockedDays?.includes(day); 
            }}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DateModal;
