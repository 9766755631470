import { React, useState } from "react";
import "../Styles/PersonalInfo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Navbar2 } from "./NavbarTwo";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";
import { Navbar4 } from "./NavbarFour";

const PersonalInfo = () => {
  const location = useLocation();
  //   form functionality
  const { outlet, cmpcode, estatecode, package_code } = useParams();
  const hostname = window.location.hostname;
  let apiUrl;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    apiUrl = "https://server.testgrouple.com/api/v1/beta/enduser/submitDetailsNew";
  }
  if (hostname.includes("grouple.tech")) {
    apiUrl =
      "https://production.grouple.tech/api/v1/beta/enduser/submitDetails";
  }

  const [formData, setFormData] = useState({
    personName: "",
    VATnumber: "",
    address: "",
    city: "",
    postalCode: "",
    note: "",
    country: "",
    state: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    personName: "",
    city: "",
    postalCode: "",
    country: "",
    state: "",
  });
  const reservationData = location.state.reservationData || {};
  const loggedInUserData = location.state.loggedInUserData || {};
  // console.log("loggedInUserData",loggedInUserData)

  const [value, setValue] = useState("");

  const validateForm = () => {
    let valid = true;
    const errors = {
      personName: "",
      city: "",
      postalCode: "",
      country: "",
      state: "",
    };
    // const nameRegex = /^\S+$/;

    if (formData.personName.trim() === "") {
      valid = false;
      errors.personName = "Please enter a name.";
    }
    if (formData.city.trim() === "") {
      valid = false;
      errors.city = "Please enter a city";
    }
    if (formData.postalCode.trim() === "") {
      valid = false;
      errors.postalCode = "Please enter a Postal Code.";
    }
    if (formData.country.trim() === "") {
      valid = false;
      errors.country = "Please enter a Country.";
    }
    if (formData.state.trim() === "") {
      valid = false;
      errors.state = "Please enter a State.";
    }
    setErrorMessage(errors);
    return valid;
  };

  const [requestSent, setRequestSent] = useState(false);
  const getItemsByCategoryType = (type) => {
    const categoryCodes =
      reservationData?.packageCategories
        ?.filter((category) => category.category_type === type)
        .map((category) => category.fnb_category_code) || [];

    return categoryCodes.reduce((acc, code) => {
      if (reservationData?.selectedItems?.[code]) {
        acc[code] = reservationData.selectedItems[code];
      }
      return acc;
    }, {});
  };

  const foodItems = getItemsByCategoryType("F");
  const beverageItems = getItemsByCategoryType("B");
  const totalAmountAfterServiceFee =
    reservationData.discountedPrice + reservationData.ServiceFee;
    const handleFormSubmit = async (e) => {
      e.preventDefault();
    
      const loggedIn = document.cookie.includes("loggedIn=true");
      if (!loggedIn) {
        window.location.href = "/signin";
        return;
      }
    
      try {
        const isFormValid = validateForm();
        if (!isFormValid) {
          return;
        }
        if (!requestSent) {
          setRequestSent(true);
          setTimeout(() => {
            setRequestSent(false);
          }, 8000);
        }
    
        const time = reservationData?.timeSlot?.split(" ");
        const startTime = time?.[0]?.slice(0, 5);
        const endTime = time?.[2]?.slice(0, 5);
    
        // Remove the entire dial code, including the "+"
        const dialCodeWithoutPlus = loggedInUserData.dialCode;
        const phoneNumberWithoutDialCode = loggedInUserData.phoneNumber.replace(
          dialCodeWithoutPlus,
          ""
        );
    
        let apiData = {
          bookerFirstName: loggedInUserData.firstName,
          bookerLastName: loggedInUserData.lastName,
          bookerEmail: loggedInUserData.email,
          bookerInternationalCode: loggedInUserData.dialCode,
          bookerPhone: phoneNumberWithoutDialCode,
          bookingCompany: outlet,
          bookingOutlet: outlet.replace(/-/g, " "),
          bookingOutletAddress: reservationData?.Outlet_address,
          bookingOutletCuisine: reservationData?.packageCusine,
          bookingPackageCategory: reservationData?.package_category,
          bookingPackage: reservationData?.packageName,
          bookingPackageDuration: JSON.stringify(reservationData?.duration),
          bookingDate: reservationData?.date,
          bookingSlotStartTime: startTime || "N/A",
          bookingSlotEndTime: endTime || "N/A",
          bookingTotalGuest: reservationData?.selectedPeople,
          bookingCurrency: reservationData?.package_currency,
          bookingPerPersonCost: reservationData?.price,
          bookingPerPersonCostWithoutDiscount: reservationData?.initialPrice,
          bookingTotalCost: totalAmountAfterServiceFee,
          bookingDiscountedAmount: reservationData?.moneyToBeSaved,
          bookingGrouplePackageCategory: reservationData?.package_tag
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          bookingNotes: formData.note || null,
          maxCapacity: reservationData?.pax_max,
          floatingCapacity: reservationData?.pax_min,
          companyCode: cmpcode,
          outletCode: estatecode,
          invoiceDetails: {
            company_name: formData.personName,
            vat_no: formData.VATnumber,
            address: formData.address,
            city: formData.city,
            postal_code: formData.postalCode,
            country: formData.country,
            state: formData.state,
          },
        };
    
        if (reservationData?.isFndB === 1) {
          apiData.foodItemSelected = {
            food: foodItems,
            beverage: beverageItems,
          };
        } else {
          apiData.foodItemSelected = "{}";
        }
    
        // console.log("apiData", apiData);
        let response = await axios.post(apiUrl, apiData);
        sessionStorage.clear();
        localStorage.clear();
        setTimeout(() => {
          let baseUrl = "/groupReservation/7gK1Yn8pQF";
          baseUrl += `/${response.data.data.random_reservation_code}?reservation_code=${response.data.data.reservation_code}&choiceA=${reservationData.choiceA}&choiceM=${reservationData.choiceM}&choiceD=${reservationData.choiceD}`;
          window.open(baseUrl, "_blank");
          window.location.href = `/location`;
        }, 100);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
    

  const estateCode = localStorage.getItem("estateCode");
  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode);

  return (
    <>
      <Navbar4 />
      <div className="mobile-none-div2">
        <form className="reservationForm2">
          <h1>Personal Details</h1>

          <label htmlFor="firstName">Company/Person name*</label>
          <input
            type="text"
            id="personName"
            name="personName"
            value={formData.personName}
            onChange={(e) => {
              const capitalizedValue =
                e.target.value.charAt(0).toUpperCase() +
                e.target.value.slice(1);

              setErrorMessage({ ...errorMessage, personName: "" });
              setFormData({
                ...formData,
                personName: capitalizedValue,
              });
            }}
            required
          />
          {errorMessage.personName && (
            <p style={{ color: "red" }}>{errorMessage.personName}</p>
          )}

          <label htmlFor="lastName">VAT number</label>
          <input
            type="number"
            id="VATnumber"
            name="VATnumber"
            value={formData.VATnumber}
            onChange={(e) => {
              setErrorMessage({ ...errorMessage, VATnumber: "" });
              setFormData({
                ...formData,
                VATnumber: e.target.value,
              });
            }}
          />
          {errorMessage.VATnumber && (
            <p style={{ color: "red" }}>{errorMessage.VATnumber}</p>
          )}

          <label htmlFor="email">Street address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={(e) => {
              setErrorMessage({ ...errorMessage, address: "" });
              setFormData({ ...formData, address: e.target.value });
            }}
          />

          <label htmlFor="firstName">City*</label>
          <input
            type="text"
            id="City"
            name="City"
            value={formData.city}
            onChange={(e) => {
              setErrorMessage({ ...errorMessage, city: "" });
              setFormData({
                ...formData,
                city: e.target.value,
              });
            }}
            required
          />
          {errorMessage.city && (
            <p style={{ color: "red" }}>{errorMessage.city}</p>
          )}

          <label htmlFor="lastName">Postal code</label>
          <input
            type="number"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={(e) => {
              setErrorMessage({ ...errorMessage, postalCode: "" });
              setFormData({
                ...formData,
                postalCode: e.target.value,
              });
            }}
            required
          />
          {errorMessage.postalCode && (
            <p style={{ color: "red" }}>{errorMessage.postalCode}</p>
          )}

          <label htmlFor="firstName">Country*</label>
          <input
            type="text"
            id="Country"
            name="Country"
            value={formData.country}
            onChange={(e) => {
              setErrorMessage({ ...errorMessage, country: "" });
              setFormData({
                ...formData,
                country: e.target.value,
              });
            }}
            required
          />
          {errorMessage.country && (
            <p style={{ color: "red" }}>{errorMessage.country}</p>
          )}

          <label htmlFor="lastName">State/Province*</label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={(e) => {
              setErrorMessage({ ...errorMessage, state: "" });
              setFormData({
                ...formData,
                state: e.target.value,
              });
            }}
            required
          />
          {errorMessage.state && (
            <p style={{ color: "red" }}>{errorMessage.state}</p>
          )}

          <label htmlFor="notes"> Reservation notes (Optional)</label>

          <textarea
            onChange={(e) => {
              setFormData({ ...formData, note: e.target.value });
            }}
            value={formData.note}
            className="reservationInput4"
            type="text"
            name="note"
            id="note"
          ></textarea>
        </form>

        {/* confirm button */}
        <div className="ConfirmButtondiv">
          <h4>
            <label
              style={{
                marginRight: "1rem",
                position: "relative",
                display: "inline-block",
              }}
            >
              <input
                type="checkbox"
                name="agreement"
                checked={value}
                onChange={(e) => setValue(e.target.checked)}
                style={{ display: "none" }}
              />
              <span
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  backgroundColor: value ? "#4A6AFE" : "#e5e5ea",
                  border: `1px solid ${value ? "#4A6AFE" : "#CCCCCC"}`,
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
              />
              {value && (
                <span
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "30%",
                    transform: "rotate(45deg)",
                    width: "8px",
                    height: "12px",
                    borderStyle: "solid",
                    borderWidth: "0 2px 2px 0",
                    borderColor: "#FFFFFF",
                  }}
                />
              )}
            </label>
            By clicking ”reserve” you agree to{" "}
            <a
              href="https://grouple.in/privacy"
              target="blank"
              style={{ margin: " 0rem 0.3rem" }}
            >
              Terms and Condition
            </a>
          </h4>
          <button
            type="submit"
            onClick={handleFormSubmit}
            disabled={!value || requestSent}
            style={{
              cursor: !value ? "not-allowed" : "pointer",
              backgroundColor: themeColor,
            }}
          >
            Reserve
          </button>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
