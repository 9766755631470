import React, { useState, useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "../Styles/PackageInfo.css";
import header from "../Images/foodHeader.png";
import "../Styles/FoodMenu.css";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";
import axios from "axios";

const PackageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
      fill="#4A6AFE"
    />
    <path
      d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
      fill="#DBE1FF"
    />
  </svg>
);

const BestValueIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <path
      d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
      fill="#EE4622"
      stroke="#EE4622"
      strokeWidth="0.875"
      strokeLinejoin="round"
    />
  </svg>
);

const DurationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M11 6C11 8.76 8.76 11 6 11C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1C8.76 1 11 3.24 11 6Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.85494 7.58988L6.30494 6.66488C6.03494 6.50488 5.81494 6.11988 5.81494 5.80488V3.75488"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PeopleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NonVegIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#963415" />
    <path
      d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
      fill="#963415"
    />
  </svg>
);

const VegsIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#009600" />
    <circle cx="8" cy="8" r="4" fill="#009600" />
  </svg>
);

const PackageInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const edit = searchParams.get("edit");
  const [loading, setLoading] = useState(true);
  const { outlet, cmpcode, estatecode, package_code } = useParams();
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [itemDetails, setItemDetails] = useState([]);
  const [packageCategories, setPackageCategories] = useState([]);
  const [data2, setData2] = useState({});

  let reservationDatafromDateAndTime =
    location.state?.reservationDatafromDateAndTime || {};

    // console.log(reservationDatafromDateAndTime)
  const fetchFoodAndBeverage = async () => {
    const hostname = window.location.hostname;
    setLoading(true);
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl =
        "https://server.testgrouple.com/api/v1/enduser/fnb/getFnbDetails";
    } else if (hostname.includes("grouple.tech")) {
      // apiUrl = https://production.grouple.tech/api/v1/enduser/comapny/merchantInformation/${companyName};
    }

    try {
      const response = await axios.post(apiUrl, {
        cmpcode,
        estatecode,
        package_code,
      });

      if (response.status === 200 && response.data && response.data.data) {
        const dataObjectWithItems = response.data.data.find(
          (obj) => obj.itemDetails && obj.packageCategories
        );

        if (dataObjectWithItems) {
          setItemDetails(dataObjectWithItems.itemDetails);
          setPackageCategories(dataObjectWithItems.packageCategories);
         
          // console.log(dataObjectWithItems);
          // console.log("response", response);
        } else {
          throw new Error(
            "No valid data found for itemDetails or packageCategories"
          );
        }
      } else {
        throw new Error("Failed to fetch outlet information");
      }
    } catch (error) {
      console.error("Error fetching outlets:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFoodAndBeverage();
  }, []);

  useEffect(() => {
    if (itemDetails?.length > 0) {
      const data = {};
      packageCategories.forEach((category) => {
        const categoryCode = category.fnb_category_code;
        const categoryIndex = packageCategories.findIndex(
          (cat) => cat.fnb_category_code === categoryCode
        );
  
        data[categoryCode] = itemDetails[categoryIndex]?.fnb_item_data || [];
      });
  
      setData2(data);
  
      setSelectedItems((prevSelectedItems) => {
        const updatedSelectedItems = { ...prevSelectedItems };
  
        packageCategories.forEach((category) => {
          const categoryCode = category.fnb_category_code;
          if (category.allowed_nos === -1 && data[categoryCode]) {
            updatedSelectedItems[categoryCode] = data[categoryCode];
          }
        });
  
        sessionStorage.setItem("selectedItems", JSON.stringify(updatedSelectedItems));
        return updatedSelectedItems;
      });
    }
  }, [packageCategories, itemDetails]);
  

  if (Object.keys(reservationDatafromDateAndTime).length === 0) {
    const localStorageData = sessionStorage.getItem(
      "reservationDatafromDateAndTime"
    );
    if (localStorageData) {
      reservationDatafromDateAndTime = JSON.parse(localStorageData);
    }
  }

  const [vegOnly, setVegOnly] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Appetizer");

  const [selectedItems, setSelectedItems] = useState(() => {
  const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"));
  
  const initialSelectedItems = packageCategories.reduce((acc, category) => {
    const categoryCode = category.fnb_category_code;
    const maxItemsAllowed = category.allowed_nos;
    
    if (maxItemsAllowed === -1 && data2[categoryCode]) {
      acc[categoryCode] = data2[categoryCode]; 
    } else {
      acc[categoryCode] = [];
    }
    return acc;
  }, {});

  return storedItems || initialSelectedItems;
});


  useEffect(() => {
    if (edit === "true") {
      const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"));
      if (storedItems) {
        setSelectedItems(storedItems);
      }
    }
  }, [edit]);

  const leftContainerRef = useRef(null);

  const getThreshold = () => {
    return window.innerWidth <= 641 ? -100 : -300;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      let foundCategory = null;

      Object.keys(data2).forEach((category) => {
        const categoryElement = document.getElementById(category);
        if (categoryElement) {
          const { offsetTop, offsetHeight } = categoryElement;
          const threshold = getThreshold();

          if (
            scrollPosition >= offsetTop - threshold &&
            scrollPosition < offsetTop + offsetHeight - threshold
          ) {
            foundCategory = category;
          }
        }
      });

      setSelectedCategory(foundCategory);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [data2]);

  const handleCategoryClick = (event, categoryCode) => {
    event.preventDefault();
    setSelectedCategory(categoryCode);
    const categoryElement = document.getElementById(categoryCode);
    if (categoryElement) {
      const { top } = categoryElement.getBoundingClientRect();
      const offset = 100;
      const newPosition = window.scrollY + top - offset;

      window.scrollTo({
        top: newPosition,
        behavior: "smooth",
      });
    } else {
      console.warn(`Element with ID ${categoryCode} not found.`);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("selectedItems", JSON.stringify(selectedItems));
  }, [selectedItems]);

  const categoryChoicesCode = packageCategories.reduce((acc, category) => {
    acc[category.fnb_category_code] = category.allowed_nos;
    return acc;
  }, {});

  const categoryChoices = packageCategories.reduce((acc, category) => {
    acc[category.fnb_category] = category.allowed_nos;
    return acc;
  }, {});

  const isContinueButtonVisible = () => {
    for (let categoryCode in selectedItems) {
      if (
        selectedItems[categoryCode].length ===
          categoryChoicesCode[categoryCode] ||
        selectedItems[categoryCode].length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const isContinueButtonDisabled = () => {
    for (let categoryCode in categoryChoicesCode) {
      const maxItems = categoryChoicesCode[categoryCode];
      if (maxItems === -1) {
        if (!selectedItems[categoryCode] || selectedItems[categoryCode].length === 0) {
          return false;
        }
      } else if (
        !selectedItems[categoryCode] ||
        selectedItems[categoryCode].length !== maxItems
      ) {
        return false; 
      }
    }
    return true;
  };
  
  const handleAddRemoveItem = (categoryCode, item) => {
    setSelectedItems((prevSelectedItems) => {
      const maxItems = categoryChoicesCode[categoryCode];
  
      let updatedItems;
      if (maxItems === -1) {
        updatedItems = data2[categoryCode];
      } else {
        const categoryItems = prevSelectedItems[categoryCode] || [];
        const itemIndex = categoryItems.findIndex(
          (selectedItem) => selectedItem.fnb_item_code === item.fnb_item_code
        );
  
        if (itemIndex !== -1) {
          updatedItems = [
            ...categoryItems.slice(0, itemIndex),
            ...categoryItems.slice(itemIndex + 1),
          ];
        } else {
          if (categoryItems.length < maxItems) {
            updatedItems = [...categoryItems, item];
          } else {
            alert(`You can only add ${maxItems} items to this category`);
            return prevSelectedItems;
          }
        }
      }
  
      const updatedSelectedItems = {
        ...prevSelectedItems,
        [categoryCode]: updatedItems,
      };
  
      sessionStorage.setItem(
        "selectedItems",
        JSON.stringify(updatedSelectedItems)
      );
      return updatedSelectedItems;
    });
  };
  

  const renderItems = (items, categoryName) => {
    const filteredItems = vegOnly
      ? items.filter((item) => item.fnb_type !== "NON-VEG")
      : items;
  
    return filteredItems.map((item, i) => {
      const isItemSelected = (selectedItems[categoryName] || []).some(
        (selectedItem) => selectedItem.fnb_item_code === item.fnb_item_code
      );
  
      const maxItemsAllowed =
        categoryChoices[categoryName] !== undefined
          ? categoryChoices[categoryName]
          : Infinity;
  
      const canAddMoreItems =
        (selectedItems[categoryName] || []).length < maxItemsAllowed;
  
      return (
        <div key={i} className="FoodMenu-child">
          <div>
            <div>{item.fnb_type === "NON-VEG" ? NonVegIcon : VegsIcon}</div>
            <div className="Food-menu-item-name">{item.fnb_item_name}</div>
            <div className="Food-menu-item-desc">
              {item.fnb_item_desc.length > 90 &&
              !expandedDescriptions[item.fnb_item_code] ? (
                <>
                  {`${item.fnb_item_desc.slice(0, 90)}... `}
                  <button
                    className="read-more-button"
                    onClick={() =>
                      setExpandedDescriptions({
                        ...expandedDescriptions,
                        [item.fnb_item_code]: true,
                      })
                    }
                  >
                    Read More
                  </button>
                </>
              ) : (
                item.fnb_item_desc
              )}
            </div>
          </div>
          <div className="food-menu-picture-container">
            <img
              className="food-menu-picture"
              src={item.fnb_item_img_path}
              alt=""
            />
            <div className="button-container">
              {isItemSelected ? (
                <button
                  className="button-selected"
                  onClick={() => handleAddRemoveItem(categoryName, item)}
                  style={{ backgroundColor: themeColor }}
                >
                  Added
                </button>
              ) : (
                !isItemSelected && canAddMoreItems && (
                  <button
                    className="button-select"
                    onClick={() => handleAddRemoveItem(categoryName, item)}
                    style={{
                      color: themeColor,
                      border: `1px solid ${themeColor}`,
                    }}
                  >
                    Add
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      );
    });
  };
  

  const renderCategoryHeader = (categoryCode) => {
    const categoryNames = packageCategories.reduce((acc, category) => {
      acc[category.fnb_category_code] = category.fnb_category;
      return acc;
    }, {});
  
    const categoryName = categoryNames[categoryCode] || categoryCode;
    const selectedCount = (selectedItems[categoryName] ?? []).length;
    const choice = categoryChoices[categoryName];
  
    if (choice !== undefined) {
      if (choice === -1) {
        return selectedCount > 0
          ? `${categoryName} (${selectedCount} / Unlimited)`
          : `${categoryName} (Unlimited)`;
      } else {
        return selectedCount > 0
          ? `${categoryName} (${selectedCount} / ${choice})`
          : `${categoryName} (Choose ${choice})`;
      }
    }
  
    return categoryName;
  };
  
  

  const handleContinueClick = () => {
    const loggedIn = document.cookie.includes("loggedIn=true");
    if (!loggedIn) {
      window.location.href = "/signin";
      return;
    }
    const reservationData = {
      packageName: reservationDatafromDateAndTime.packageName,
      duration: reservationDatafromDateAndTime.duration,
      initialPrice: reservationDatafromDateAndTime.initialPrice,
      initialDiscountedPrice:
        reservationDatafromDateAndTime.initialDiscountedPrice,
      bestValue: reservationDatafromDateAndTime.bestValue,
      package_tag: reservationDatafromDateAndTime.package_tag,
      date: reservationDatafromDateAndTime.date,
      timeSlot: reservationDatafromDateAndTime.timeSlot,
      displayTime: reservationDatafromDateAndTime.displayTime,
      discountedPrice: reservationDatafromDateAndTime.discountedPrice,
      price: reservationDatafromDateAndTime.price,
      selectedPeople: reservationDatafromDateAndTime.selectedPeople,
      moneyToBeSaved: reservationDatafromDateAndTime.moneyToBeSaved,
      displayDate: reservationDatafromDateAndTime.displayDate,
      isFndB: reservationDatafromDateAndTime?.isFandB,
      packageCusine: reservationDatafromDateAndTime.packageCusine,
      selectedItems,
      package_currency: reservationDatafromDateAndTime.package_currency,
      pax_min: reservationDatafromDateAndTime?.pax_min,
      pax_max: reservationDatafromDateAndTime?.pax_max,
      packageCategories,
      Outlet_address: reservationDatafromDateAndTime.Outlet_address,
      package_category: reservationDatafromDateAndTime.package_category,
      ServiceFee : reservationDatafromDateAndTime.ServiceFee,
    };
    sessionStorage.setItem("reservationData", JSON.stringify(reservationData));
    navigate(
      `/groupSummary/${outlet}/${cmpcode}/${estatecode}/${package_code}`,
      {
        state: { reservationData },
      }
    );
  };

  const estateCode = localStorage.getItem("estateCode");
  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode);

  function isFoodMenu() {
    return packageCategories.some((category) => category.category_type === "F");
  }

  function isBeverageMenu() {
    return packageCategories.some((category) => category.category_type === "B");
  }


  const renderSelectedItemsSummary = () => {
    return packageCategories.map((category, index) => {
      const itemCount = selectedItems?.[category.fnb_category_code]?.length || 0;
      if (itemCount > 0) {
        const isLastItem = index === packageCategories.length - 1;
        return (
          <span key={category.fnb_category_code}>
            {itemCount} x {category.fnb_category}
            {!isLastItem && ", "}
          </span>
        );
      }
      return null;
    });
  };

  const gobhi = "/Imagess/Calico/gobiManchurian.png";
  const calamari = "/Imagess/Calico/calamari.png";

  

  return (
    <>
      <div
        style={{
          position: "sticky",
        }}
        className="PackageInfoMain"
      >
        <div className="PackageInfoInnerLeft">
          <img className="headerImgPackage" src={header} alt="foodImage" />
          <div className="packageLeftOne">
            <div className="packageNameDiv">
              <div>
                {PackageIcon}
                {reservationDatafromDateAndTime?.package_tag}
              </div>
              {reservationDatafromDateAndTime?.bestValue && (
                <div>
                  {BestValueIcon}
                  BEST VALUE
                </div>
              )}
            </div>
            <h1>{reservationDatafromDateAndTime?.packageName}</h1>
            <p>{reservationDatafromDateAndTime?.packageCusine}</p>
            <div className="inside-nonAlcoholic">
              <div
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                {DurationIcon}
                {reservationDatafromDateAndTime.displayTime}
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#8E8E93" />
                </svg>
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                {PeopleIcon}
                {reservationDatafromDateAndTime.selectedPeople} Guests
              </div>
            </div>
          </div>

          <div className="packageLeftTwo">
            <div className="foodMenu-container">
              <div className="left-container" ref={leftContainerRef}>
                <ul>
                  <li>
                    {isFoodMenu() && (
                      <strong className="strong">Food menu</strong>
                    )}
                  </li>
                  {packageCategories
                    .filter((category) => category.category_type === "F")
                    .map((category, i) => (
                      <li key={i}>
                        <a
                          href={`#${category.fnb_category_code}`}
                          onClick={(event) =>
                            handleCategoryClick(
                              event,
                              category.fnb_category_code
                            )
                          }
                          className={`category ${
                            selectedCategory === category.fnb_category_code
                              ? "active"
                              : ""
                          } ${
                            category === "Food menu" ? "food-menu-category" : ""
                          } ${
                            category === "Beverage menu"
                              ? "beverage-menu-category-res"
                              : ""
                          }`}
                        >
                          {category.fnb_category}
                        </a>
                      </li>
                    ))}
                  <li>
                    {isBeverageMenu() && (
                      <strong className="strong">Beverage menu</strong>
                    )}
                  </li>
                  {packageCategories
                    .filter((category) => category.category_type === "B")
                    .map((category, i) => (
                      <li key={i}>
                        <a
                          href={`#${category.fnb_category_code}`}
                          onClick={(event) =>
                            handleCategoryClick(
                              event,
                              category.fnb_category_code
                            )
                          }
                          className={`category ${
                            selectedCategory === category.fnb_category_code
                              ? "active"
                              : ""
                          } ${
                            category === "Food menu" ? "food-menu-category" : ""
                          } ${
                            category === "Beverage menu"
                              ? "beverage-menu-category-res"
                              : ""
                          }`}
                        >
                          {category.fnb_category}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="right-container">
                {Object.keys(data2).map(
                  (categoryCode, index) =>
                    data2[categoryCode]?.length > 0 && (
                      <section key={index} id={categoryCode}>
                        <div className="max-width">
                          <div className="FoodCategoryHeading">
                            <h2 id={categoryCode}>
                              {renderCategoryHeader(categoryCode)}
                            </h2>
                            {categoryCode === "GFBC801" && (
                              <div className="vegOnlyToggleDiv">
                                <span>Veg only</span>
                                <div className="toggle-wrapper">
                                  <input
                                    className="toggle-checkbox"
                                    type="checkbox"
                                    checked={vegOnly}
                                    onChange={() => setVegOnly(!vegOnly)}
                                  />
                                  <div className="toggle-container">
                                    <div className="toggle-button">
                                      <div className="toggle-button-circles-container"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              categoryCode === "spiritsCode" ? "aaaa" : "bbbb"
                            }
                          >
                            {Array.isArray(data2[categoryCode]) ? (
                              renderItems(data2[categoryCode], categoryCode)
                            ) : (
                              <div className="food-menu-wrapper-parent">
                                {Object.keys(data2[categoryCode]).map(
                                  (subCategory, subIndex) => (
                                    <div key={subIndex}>
                                      <h1 className="FoodCategoryHeading">
                                        {subCategory}
                                      </h1>
                                      <div className="food-menu-wrapper-child">
                                        {renderItems(
                                          data2[categoryCode][subCategory],
                                          categoryCode
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )
                )}

                {/* {/ footer /} */}
                <div
                  className="PackageInfofooter"
                  style={{
                    display: isContinueButtonVisible() ? "flex" : "none",
                  }}
                >
                  <div className="continue-container">
                    <div className="continue-container-01">
                      <p className="continuecontainerItemsAdded">items added</p>
                      <p className="continuecontainerItemsSummary">
                        {renderSelectedItemsSummary()}
                      </p>
                    </div>
                    <button
                      onClick={handleContinueClick}
                      disabled={!isContinueButtonDisabled()}
                      style={{
                        cursor: isContinueButtonDisabled()
                          ? "pointer"
                          : "not-allowed",
                        color: isContinueButtonDisabled() ? "#fff" : "#fff",
                        boxShadow: isContinueButtonDisabled()
                          ? "none"
                          : "0px -4px 12px 0px rgba(34, 34, 34, 0.12)",
                        backgroundColor: isContinueButtonDisabled()
                          ? "#1E5A53"
                          : "#ccc",
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageInfo;


