import { React, useEffect, useState } from "react";
import "../Styles/PackageDeals.css";
import { Link, useNavigate, useParams } from "react-router-dom";

const BestValueIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <path
      d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
      fill="#EE4622"
      stroke="#EE4622"
      strokeWidth="0.875"
      strokeLinejoin="round"
    />
  </svg>
);

const PackageDeals = ({ outletData , outletColor}) => {
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packages, setPackages] = useState([]);
  const { outlet, cmpcode, estatecode } = useParams();
  const navigate = useNavigate();

  const handlePackageClick = (packageType) => {
    setSelectedPackage(packageType);
  };

  const handleNavigate = (pkg, pkgCategory) => {
    navigate(`/groupSize/${outlet}/${cmpcode}/${estatecode}/${pkg.package_code}`, {
      state: {
        address: outletData.address,
        packageCategoryName: pkgCategory.package_category_name,
      },
    });
  };

  useEffect(() => {
    if (outletData && outletData.packages) {
      const uniquePackages = outletData.packages.reduce((acc, current) => {
        const x = acc.find(
          (item) => item.package_category_code === current.package_category_code
        );
        return !x ? acc.concat([current]) : acc;
      }, []);
      setPackages(uniquePackages);
      if (uniquePackages.length > 0) {
        setSelectedPackage(uniquePackages[0].package_category_name);
      }
    }
  }, [outletData]);

  return (
    <div className="package">
      <h1>What you can experience</h1>
      <div className="package-buttons">
        {packages
          .filter(
            (pkgCategory) =>
              pkgCategory.package_category_code &&
              pkgCategory.package_category_name
          )
          .map((pkgCategory, index) => (
            <button
              key={`${pkgCategory.package_category_code}-${index}`}
              onClick={() =>
                handlePackageClick(pkgCategory.package_category_name)
              }
              className={
                selectedPackage === pkgCategory.package_category_name
                  ? "focused"
                  : ""
              }
            >
              {pkgCategory.package_category_name}
            </button>
          ))}
      </div>

      {packages.map(
        (pkgCategory, index) =>
          selectedPackage === pkgCategory.package_category_name && (
            <div
              className="package-cards"
              key={`${pkgCategory.package_category_code}-${index}`}
            >
              {pkgCategory.package.map((pkg) => (
                <div className="card1" key={pkg.package_name}>
                  <div
                    onClick={() => handleNavigate(pkg, pkgCategory)}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <div
                      className="img-card"
                      style={{ backgroundColor: `${pkg.package_title_color}` }}
                    ></div>
                
                    <div className="inner-card">
                      <div className="regular">
                        <div className="inside-regular-parent">
                          <div className="inside-regular3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                                fill="#4A6AFE"
                              />
                              <path
                                d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                                fill="#DBE1FF"
                              />
                            </svg>
                            {pkg.package_tag}
                          </div>
                          {pkg.package_tag2 && (
                            <div className="inside-regular2">
                              {BestValueIcon}
                              {pkg.package_tag2}
                            </div>
                          )}
                        </div>

                        <div className="choose">
                          <button
                            className="choose-bttn"
                            style={{
                              backgroundColor: outletColor,
                              color: "white",
                            }}
                          >
                            Choose
                          </button>
                        </div>
                      </div>

                      <div className="non-alcoholic">
                        <div>
                          <h2>{pkg.package_name}</h2>
                        </div>
                        <h3>{pkg.package_price_tag}</h3>
                        <h4>
                          {pkg.package_discount_price > 0 ? (
                            <>
                              <span>
                                {pkg.package_currency}{" "}
                                {parseFloat(pkg.package_actual_price).toFixed(
                                  2
                                ) %
                                  1 ===
                                0
                                  ? parseInt(pkg.package_actual_price, 10)
                                  : pkg.package_actual_price}
                              </span>{" "}
                              {pkg.package_currency}{" "}
                              {parseFloat(pkg.package_discount_price).toFixed(
                                2
                              ) %
                                1 ===
                              0
                                ? parseInt(pkg.package_discount_price, 10)
                                : pkg.package_discount_price}
                            </>
                          ) : (
                            <>
                              <span></span>
                              {pkg.package_currency}{" "}
                              {parseFloat(pkg.package_actual_price).toFixed(2) %
                                1 ===
                              0
                                ? parseInt(pkg.package_actual_price, 10)
                                : pkg.package_actual_price}
                            </>
                          )}
                          <span
                            style={{
                              fontFamily: "Gilroy-Regular",
                              fontWeight: "400",
                              color: "#242426",
                              fontSize: "1.1rem",
                            }}
                          >
                            /{pkg.package_type}
                          </span>
                        </h4>
                      </div>
                    </div>

                    <div className="regular-food">
                      <div className="food-card">
                        <div className="icon-div" style={{ color: "black" }}>
                          What's Included
                        </div>

                        <div className="whatIncludes">
                          <ul>
                            {pkg.package_info &&
                              pkg.package_info
                                .replace(/<\/?p>/g, "")
                                .replace(/\/?p>/g, "")
                                .replace(/<br\/?>/g, "")
                                .replace(/<b>What’s Included<\/b>/g, "")
                                .split("<li>")
                                .map(
                                  (item, index) =>
                                    item &&
                                    item.trim() && (
                                      <li key={index}>
                                        {item.replace("</li>", "").trim()}
                                      </li>
                                    )
                                )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
      )}
    </div>
  );
};


export default PackageDeals;
