import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../Styles/DateAndTime.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import "../Styles/Datepicker.css";
// import dayjs from "dayjs";
import { useTheme } from "./ColorContext";
import Rules from "./Rules";
import { colors } from "@mui/material";
import axios from "axios";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const PackageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
      fill="#4A6AFE"
    />
    <path
      d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
      fill="#DBE1FF"
    />
  </svg>
);

const BestValueIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <path
      d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
      fill="#EE4622"
      stroke="#EE4622"
      strokeWidth="0.875"
      strokeLinejoin="round"
    />
  </svg>
);

const DurationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M11 6C11 8.76 8.76 11 6 11C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1C8.76 1 11 3.24 11 6Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.85494 7.58988L6.30494 6.66488C6.03494 6.50488 5.81494 6.11988 5.81494 5.80488V3.75488"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PeopleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CurvedLine = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="400"
    height="18"
    viewBox="0 0 400 18"
    fill="none"
  >
    <path
      d="M1 1C99.9997 22.3663 289.999 22.3003 389 1"
      stroke="url(#paint0_linear_2112_1851)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2112_1851"
        x1="389"
        y1="9.00026"
        x2="0.999997"
        y2="9.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B37E7D" stop-opacity="0.1" />
        <stop offset="0.510417" stop-color="#B37E7D" />
        <stop offset="1" stop-color="#B37E7D" stop-opacity="0.1" />
      </linearGradient>
    </defs>
  </svg>
);

const parseHTML = (html) => {
  return { __html: html };
};

const customNextIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M9 18L15 12L9 6"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const customPrevIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="24"
    viewBox="0 0 44 24"
    fill="none"
  >
    <path
      d="M15 6L9 12L15 18"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const parsePackageDetails = (content) => {
  // Remove all `onClick` attributes
  const cleanedContent = content.replace(/ onClick="[^"]*"/g, "");
  
  const parser = new DOMParser();
  const doc = parser.parseFromString(cleanedContent, "text/html");
  const sections = [];
  let currentSection = { heading: "", content: "" };

  doc.body.childNodes.forEach((node) => {
    if (node.tagName === "P" && node.querySelector("b")) {
      if (currentSection.heading) {
        sections.push(currentSection);
      }
      currentSection = {
        heading: node.querySelector("b").innerText,
        content: "",
      };
    } else {
      currentSection.content += node.outerHTML || node.textContent;
    }
  });

  if (currentSection.heading) {
    sections.push(currentSection);
  }

  return sections.filter(
    (section) => section.heading !== "What you’ll experience"
  );
};


const DateAndTime = () => {
  const { themeColor } = useTheme();
  const [packageData, setPackageData] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [package_currency, setPackage_currency] = useState("");
  const [initialPrice, setInitialPrice] = useState(0);
  const [packageSlabs, setPackageSlabs] = useState([]);
  const [initialDiscountedPrice, setInitialDiscountedPrice] = useState(0);
  const [package_long_desc, setPackage_long_desc] = useState("");
  const [isFixedDate, setIsFixedDate] = useState(0);
  const { outlet, cmpcode, estatecode, package_code } = useParams();
  const location = useLocation();
  const { address, packageCategoryName } = location.state || {};

  useEffect(() => {
    if (address) {
      sessionStorage.setItem("address", address);
    }
    if (packageCategoryName) {
      sessionStorage.setItem("packageCategoryName", packageCategoryName);
    }
  }, [address, packageCategoryName]);

  const storedAddress = sessionStorage.getItem("address");
  const storedPackageCategoryName = sessionStorage.getItem(
    "packageCategoryName"
  );

  const navigate = useNavigate();
  const divStyle = {
    backgroundColor:
      themeColor === "#060807"
        ? "#E6E6E6"
        : themeColor === "#D4A95A"
        ? "#F6EEDE"
        : "#DBE1FF",

    color:
      themeColor === "#060807"
        ? "#060807"
        : themeColor === "#D4A95A"
        ? "#00000"
        : "#4A6AFE",
  };

  const fetchPackageDetails = async () => {
    const hostname = window.location.hostname;
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl = `https://server.testgrouple.com/api/v1/enduser/packages/getPackageDetails`;
    } else if (hostname.includes("grouple.tech")) {
      // apiUrl = `https://production.grouple.tech/api/v1/enduser/comapny/getOutlettInformation/${outletCode}`;
    }

    try {
      const response = await axios.post(apiUrl, { package_code });

      if (response.status === 200) {
        const packageData = response.data.data.find(
          (obj) => obj.package_code !== undefined
        );

        if (packageData) {
          setPackageData(packageData);

          const hoursFromAPI = packageData.lead_time;
          const daysFromHours = Math.ceil(hoursFromAPI / 24);
          const today = new Date();
          today.setDate(today.getDate() + daysFromHours);
          setMinDate(today);

          setPackage_currency(packageData.package_currency);
          const payment_currency = packageData.payment_currency;
          setInitialPrice(packageData.package_actual_price);
          setInitialDiscountedPrice(packageData.package_discount_price);
          setPackage_long_desc(packageData.package_long_desc);
          setPackageSlabs(packageData.package_slabs);
          setIsFixedDate(packageData.is_fixed_date);
        } else {
          throw new Error("No valid data found with package_code");
        }
      } else {
        throw new Error("Failed to fetch packages");
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackageDetails();
  }, []);

  const [marks, setMarks] = useState([]);
  const is_min_slider = 1;
  useEffect(() => {
    if (packageData?.package_slabs) {
      const slabs = packageData.package_slabs;
      let newMarks;
      if (slabs.length === 1) {
        newMarks = [
          { value: slabs[0].min_pax, label: `${slabs[0].min_pax}` },
          { value: slabs[0].max_pax, label: `${slabs[0].max_pax}` },
        ];
      } else {
        newMarks = is_min_slider
          ? slabs.map((slab) => ({
              value: slab.min_pax,
              label: `${slab.min_pax}`,
            }))
          : [
              { value: slabs[0].min_pax, label: `${slabs[0].min_pax}` },
              ...slabs.map((slab) => ({
                value: slab.max_pax,
                label: `${slab.max_pax}`,
              })),
            ];
      }
      setMarks(newMarks);
    }
  }, [packageData, is_min_slider]);

  // *************** Date *************
  const [date, setDate] = useState(null);
  const getCurrentDay = (selectedDate) => {
    if (!selectedDate) return null;
    return selectedDate
      .toLocaleDateString("en-US", { weekday: "short" })
      .toLowerCase();
  };
  const [currentDay, setCurrentDay] = useState(null);

  useEffect(() => {
    if (date) {
      setCurrentDay(getCurrentDay(date));
    } else {
      setCurrentDay(null);
    }
  }, [date]);

  useEffect(() => {
    localStorage.setItem("selectedDate", date);
  }, [date]);

  const dayClassName = () => "custom-day";
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const CustomInput = ({ value, onClick }) => (
    <div
      className={`custom-datepicker-input ${isCalendarOpen ? "open" : ""}`}
      onClick={() => {
        onClick();
        setIsCalendarOpen(!isCalendarOpen);
      }}
    >
      {value ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            <span className="dateHeading">DATE</span>
            <span className="DateSelectedValue">{value}</span>
          </div>
          <span>
            <ChevronDownIcon
              className={`chevron-icon ${isCalendarOpen ? "up" : "down"}`}
            />
          </span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span className="dateHeading">DATE</span>{" "}
          <ChevronDownIcon
            className={`chevron-icon ${isCalendarOpen ? "up" : "down"}`}
          />
        </div>
      )}
    </div>
  );

  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="custom-header">
      <div className="custom-header-month-wrapper">
        <div
          onClick={decreaseMonth}
          className="custom-prev-btn"
          disabled={prevMonthButtonDisabled}
        >
          {customPrevIcon}
        </div>
        <div className="custom-current-month">
          {date.toLocaleDateString("en-US", { month: "long", year: "numeric" })}
        </div>
        <div
          onClick={increaseMonth}
          className="custom-next-btn"
          disabled={nextMonthButtonDisabled}
        >
          {customNextIcon}
        </div>
      </div>

      <div className="custom-day-names">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
          <div key={index} className="custom-day-name">
            {day}
          </div>
        ))}
      </div>
    </div>
  );

  // *************** Time Slot *************
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [displayedTimeSlot, setDisplayedTimeSlot] = useState("Time Slot");

  useEffect(() => {
    if (isFixedDate === 1) {
      const fixedDate = new Date(packageData?.fixed_min_date);
      setDate(fixedDate);

      const fixedSlot = packageData?.package_time_slots[0];
      const fixedSlotValue = `${fixedSlot.package_min_time_slot} - ${fixedSlot.package_max_time_slot}`;
      const fixedSlotLabel = `${new Date(
        `1970-01-01T${fixedSlot.package_min_time_slot}`
      ).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })} - ${new Date(
        `1970-01-01T${fixedSlot.package_max_time_slot}`
      ).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`;

      setSelectedTimeSlot(fixedSlotValue);
      setDisplayedTimeSlot(fixedSlotLabel);
    }
  }, [
    isFixedDate,
    packageData?.fixed_min_date,
    packageData?.package_time_slots,
  ]);

  const filteredTimeSlots =
    currentDay && packageData?.package_time_slots
      ? packageData?.package_time_slots
          .filter((slot) => slot.package_day === currentDay)
          .map((slot) => ({
            value: `${slot.package_min_time_slot} - ${slot.package_max_time_slot}`,
            label: `${new Date(
              `1970-01-01T${slot.package_min_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} - ${new Date(
              `1970-01-01T${slot.package_max_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}`,
          }))
      : [];

  const handleSelectTimeSlot = (timeSlot) => {
    if (!date) return;
    setSelectedTimeSlot(timeSlot);
    setDisplayedTimeSlot(
      filteredTimeSlots.find((option) => option.value === timeSlot).label
    );
    setIsDropdownOpen(false);
  };

  // ************SLIDER LOGIC*******
  const [selectedPeople, setSelectedPeople] = useState(0);
  const [price, setPrice] = useState(initialPrice);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [moneyToBeSaved, setMoneyToBeSaved] = useState(0);
  const calculateServiceFee = (price) => {
    return price * 0.125;
  };

  const ServiceFee = calculateServiceFee(price);

  useEffect(() => {
    if (!packageSlabs || !Array.isArray(packageSlabs)) {
      console.error("packageSlabs is not defined or not an array");
      return;
    }

    let totalCost =
      packageData?.package_type === "group"
        ? initialPrice
        : initialPrice * selectedPeople;
    let discount = 0;

    const discountSlab = packageSlabs.find(
      (slab) => selectedPeople >= slab.min_pax && selectedPeople <= slab.max_pax
    );

    if (discountSlab && discountSlab.discount_price > 0) {
      switch (discountSlab.discount_type) {
        case "fixed_abs":
          discount = parseFloat(discountSlab.discount_price);
          break;
        case "percent_abs":
          discount =
            (totalCost * parseFloat(discountSlab.discount_price)) / 100;
          break;
        case "guest_abs":
          discount = parseFloat(discountSlab.discount_price) * selectedPeople;
          break;
        case "guest_percent":
          discount =
            ((initialPrice * parseFloat(discountSlab.discount_price)) / 100) *
            selectedPeople;
          break;
        default:
          discount = 0;
          break;
      }
    }

    const finalDiscountedPrice = totalCost - discount;
    setPrice(totalCost);
    setDiscountedPrice(finalDiscountedPrice);
    setMoneyToBeSaved(discount);
  }, [selectedPeople, initialPrice, packageData, packageSlabs]);

  const handleInputChange = (value) => {
    if (!isNaN(value) && value >= 1 && value <= 350) {
      setSelectedPeople(value);
    } else {
      const clampedValue = value >= 1 ? Math.min(Math.max(value, 10), 350) : 0;
      setSelectedPeople(clampedValue);
    }
  };

  const handleInputNumberChange = (event) => {
    const value = parseInt(event.target.value, 10);
    handleInputChange(value);
  };

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    handleInputChange(value);
  };

  const renderDynamicNumber = () => {
    const nextSlab = packageSlabs.find((slab) => selectedPeople < slab.min_pax);

    if (nextSlab) {
      const additionalGuestsNeeded = nextSlab.min_pax - selectedPeople;
      const discountPrice = parseFloat(nextSlab.discount_price);
      const discountAmount =
        nextSlab?.discount_type === "fixed_abs"
          ? discountPrice
          : (discountPrice / 100) * initialPrice;

      if (discountPrice > 0 && selectedPeople > 0) {
        return (
          <div className="moneySavedDiv2" style={divStyle}>
            Add {additionalGuestsNeeded} more guests to enjoy {package_currency}{" "}
            {discountAmount.toLocaleString()} OFF
          </div>
        );
      }
    }

    return null;
  };

  const renderDynamicSaved = () => {
    return (
      <>
        {moneyToBeSaved !== undefined && (
          <div className="moneySavedDiv">
            🥳 You saved {package_currency} {moneyToBeSaved.toLocaleString()}
          </div>
        )}
      </>
    );
  };
  // };
  // /**** Validation ******* */
  const [validationErrors, setValidationErrors] = useState({
    date: "",
    timeSlot: "",
    selectedPeople: "",
  });

  const clearDateError = () => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, date: "" }));
  };
  const clearTimeSlotError = () => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, timeSlot: "" }));
  };
  const clearSelectedPeopleError = () => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      selectedPeople: "",
    }));
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {
      date: "",
      timeSlot: "",
      selectedPeople: "",
    };
    if (!date) {
      errors.date = "Please select a date";
      isValid = false;
    }
    if (!selectedTimeSlot) {
      errors.timeSlot = "Please select a time slot";
      isValid = false;
    }
    if (
      selectedPeople < packageData.min_pax ||
      selectedPeople > packageData.maxPax
    ) {
      errors.selectedPeople = `Please select number of guests between ${packageData.min_pax} - ${packageData.max_pax}`;
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };
  useEffect(() => {
    const storedData = sessionStorage.getItem("reservationDatafromDateAndTime");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setDate(parsedData.date ? new Date(parsedData.date) : null);
      setSelectedTimeSlot(parsedData.timeSlot);
      setSelectedPeople(parsedData.selectedPeople);
      setDisplayedTimeSlot(parsedData.displayTime);
    }
  }, []);

  const formatDate = (date, format = "YYYY-MM-DD") => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .reverse()
      .join("-");
  };

  const handleContinue = () => {
    if (!validateForm()) {
      return;
    }

    const reservationDatafromDateAndTime = {
      date: date instanceof Date ? formatDate(date) : null,
      timeSlot: selectedTimeSlot,
      displayTime: displayedTimeSlot,
      price,
      discountedPrice,
      initialPrice,
      initialDiscountedPrice,
      selectedPeople,
      duration: packageData?.duration_hrs,
      displayDate: date,
      moneyToBeSaved,
      ServiceFee,
      package_currency,
      package_tag: packageData?.package_tag,
      bestValue: packageData?.package_tag2,
      packageName: packageData?.package_name,
      packageCusine: packageData?.package_cuisine,
      isFandB: packageData?.is_fnb,
      pax_min: packageData?.min_pax,
      pax_max: packageData?.max_pax,
      Outlet_address: storedAddress,
      package_category: storedPackageCategoryName,
    };

    sessionStorage.setItem(
      "reservationDatafromDateAndTime",
      JSON.stringify(reservationDatafromDateAndTime)
    );

    if (packageData?.is_fnb !== 1) {
      const loggedIn = document.cookie.includes("loggedIn=true");
      if (!loggedIn) {
        window.location.href = "/signin";
        return;
      }
    }

    setDate(null);
    setSelectedTimeSlot(null);
    setSelectedPeople(packageData?.min_pax);
    setPrice(initialPrice);
    setDiscountedPrice(initialDiscountedPrice);

    if (packageData?.is_fnb === 1) {
      navigate(`/FandB/${outlet}/${cmpcode}/${estatecode}/${package_code}`, {
        state: { reservationDatafromDateAndTime },
      });
    } else {
      navigate(
        `/groupSummary/${outlet}/${cmpcode}/${estatecode}/${package_code}`,
        { state: { reservationData: reservationDatafromDateAndTime } }
      );
    }
  };

  const handleBookAReservation = () => {
    const reservationDatafromDateAndTime = {
      price,
      discountedPrice,
      initialPrice,
      initialDiscountedPrice,
      duration: packageData?.duration_hrs,
      package_currency,
      package_tag: packageData?.package_tag,
      bestValue: packageData?.package_tag2,
      packageName: packageData?.package_name,
      packageCusine: packageData?.package_cuisine,
      isFandB: packageData?.is_fnb,
      pax_min: packageData?.min_pax,
      pax_max: packageData?.max_pax,
      packageSlabs: packageSlabs,
      packageType: packageData?.package_type,
      packageCurrency: packageData?.package_currency,
      isCoupon: packageData?.is_coupon,
      isFixedDate: isFixedDate,
      packageData: packageData,
      minDate: minDate,
      ServiceFee,
      fixedMinDate: packageData?.fixed_min_date,
      Outlet_address: storedAddress,
      package_category: storedPackageCategoryName,
    };

    sessionStorage.setItem(
      "reservationDatafromDateAndTime",
      JSON.stringify(reservationDatafromDateAndTime)
    );
    navigate(`/datemobile/${outlet}/${cmpcode}/${estatecode}/${package_code}`, {
      state: { reservationDatafromDateAndTime },
    });
  };

  const sections = parsePackageDetails(package_long_desc);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const imgElements = document.querySelectorAll(
      'img[data-has-onclick="true"]'
    );
    imgElements.forEach((imgElement) => {
      imgElement.addEventListener("click", () =>
        handleImageClick(imgElement.src)
      );
    });

    return () => {
      imgElements.forEach((imgElement) => {
        imgElement.removeEventListener("click", () =>
          handleImageClick(imgElement.src)
        );
      });
    };
  }, [sections]);

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc);
    setIsModalOpen(true);
    console.log("working");
  };

  const closeModalll = () => {
    setIsModalOpen(false);
    setSelectedImage("");
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
      </div>
    );
  }

  const valuetext = (value) => {
    return `${value}`;
  };

  const formatPrice = (price) => {
    const numericPrice = parseFloat(price);
    return numericPrice % 1 === 0
      ? numericPrice.toFixed(0)
      : numericPrice.toFixed(2);
  };

  const blockedDays = [1, 2];

  return (
    <>
      <div className="PackageInfoMain2">
        {/* **** Left Side Content ***** */}
        <div className="PackageInfoInnerLeft2">
          <div className="packageLeftOne2">
            <div className="packageNameDiv2">
              <div>
                {PackageIcon}
                {packageData.package_tag}
              </div>
              {packageData.package_tag2 && (
                <div>
                  {BestValueIcon}
                  {packageData.package_tag2}
                </div>
              )}
            </div>
            <h1>{packageData.package_name}</h1>
            <p>{packageData.package_cuisine}</p>
            <div className="inside-nonAlcoholic">
              <div>
                {DurationIcon}
                {packageData.duration_hrs} hours
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#8E8E93" />
                </svg>
              </div>
              <div>
                {PeopleIcon}
                {packageData.min_pax} pax min
              </div>
            </div>
          </div>

          <div className="curved-line">{CurvedLine}</div>

          <div className="PackageInDetailWrapper">
            <h1 className="PackageDeatailsHeaderr">What you’ll experience</h1>

            {sections.map((section, index) => (
              <div className="PackageInDeatilChild" key={index}>
                <div className="PackageInDetailChildHeadingNew">
                  {section.heading}
                </div>
                <div
                  className="PackageInDetailChildParaNew"
                  dangerouslySetInnerHTML={{
                    __html: section.content.replace(/<\/?p>/g, ""),
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {/* **** Right Side Content **** */}
        <div
          className="PackageInfoInnerRightDT"
          style={{ borderTop: `8px solid ${packageData.package_title_color}` }}
        >
          <h1>Book your Reservation</h1>

          {isFixedDate === 1 ? (
            <div className="date-time-wrapper">
              <div className="date-div">
                <DatePicker
                  selected={date}
                  dateFormat="dd-MM-yyyy"
                  minDate={minDate}
                  customInput={<CustomInput />}
                  calendarClassName="custom-calendar"
                  dayClassName={dayClassName}
                  renderCustomHeader={CustomHeader}
                  disabled={true}
                />
              </div>
              <div className="slot-div" style={{ cursor: "not-allowed" }}>
                <div
                  className="slot-div"
                  onClick={() => {
                    clearTimeSlotError();
                    setIsCalendarOpen(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="custom-dropdown">
                    <div className="dropdown-header">
                      <div className="Time-placeHolder">
                        <div>
                          <div className="Time-placeholder-child">
                            TIME SLOT
                          </div>{" "}
                          <div className="Time-Value">{displayedTimeSlot}</div>
                        </div>
                        <div>
                          <ChevronDownIcon
                            className={`chevron-icon ${
                              isDropdownOpen ? "up" : "down"
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="date-time-wrapper">
              {/* /*** Date div *****  */}
              <div
                className="date-div"
                onClick={() => {
                  clearDateError();
                  setIsDropdownOpen(false);
                }}
              >
                <DatePicker
                  selected={date}
                  onChange={(newDate) => {
                    setDate(newDate);
                    setIsCalendarOpen(false);
                  }}
                  dateFormat="dd-MM-yyyy"
                  minDate={minDate}
                  customInput={<CustomInput />}
                  calendarClassName="custom-calendar"
                  dayClassName={dayClassName}
                  open={isCalendarOpen}
                  onClickOutside={() => setIsCalendarOpen(false)}
                  nextMonthButtonLabel={customNextIcon}
                  previousMonthButtonLabel={customPrevIcon}
                  renderCustomHeader={CustomHeader}
                  filterDate={(date) => {
                    const day = date.getDay();
                    return !blockedDays?.includes(day);
                  }}
                />
              </div>
              {/* *** Time div ***  */}
              <div
                className="slot-div"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                style={{ cursor: "pointer" }}
              >
                <div className="custom-dropdown">
                  <div
                    className={`dropdown-header ${
                      isDropdownOpen ? "active" : ""
                    }`}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {selectedTimeSlot ? (
                      <div className="Time-placeHolder">
                        <div>
                          <div className="Time-placeholder-child">
                            TIME SLOT
                          </div>
                          <div className="Time-Value">{displayedTimeSlot}</div>
                        </div>
                        <div>
                          <ChevronDownIcon
                            className={`chevron-icon ${
                              isDropdownOpen ? "up" : "down"
                            }`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="Time-placeHolder">
                        <div className="Time-placeholder-child">TIME SLOT</div>
                        <div>
                          <ChevronDownIcon
                            className={`chevron-icon ${
                              isDropdownOpen ? "up" : "down"
                            }`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {isDropdownOpen && (
                    <div className="dropdown-options">
                      {date ? (
                        filteredTimeSlots.length > 0 ? (
                          filteredTimeSlots.map((option) => (
                            <div
                              key={option.value}
                              className={`dropdown-option ${
                                selectedTimeSlot === option.value
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleSelectTimeSlot(option.value)}
                            >
                              {option.label}
                            </div>
                          ))
                        ) : (
                          <div className="dropdown-option">
                            No available time slots
                          </div>
                        )
                      ) : (
                        <div className="dropdown-option">
                          Please select a date first
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* *** slider div ****  */}
          <div className="guest-div">
            {selectedPeople ? (
              <span className="Selected-place">GROUP SIZE</span>
            ) : (
              ""
            )}
            <input
              placeholder={selectedPeople ? "" : "GROUP SIZE"}
              type="number"
              min={packageData.min_pax}
              max={packageData.max_pax}
              value={selectedPeople > 0 ? selectedPeople : ""}
              className="selectedPeopleInput"
              onChange={handleInputNumberChange}
              style={{
                cursor: "pointer",
                padding: selectedPeople ? "1.45rem 1rem 0.55rem 1rem" : "1rem",
              }}
            />
            {validationErrors.selectedPeople && (
              <span className="error-message">
                {validationErrors.selectedPeople}
              </span>
            )}
            <div
              className="slider-wrapper"
              style={{ border: "none", padding: "0rem" }}
            >
              <Box sx={{ width: "100%", position: "relative" }}>
                <Slider
                  aria-label="Custom marks"
                  value={selectedPeople}
                  min={packageData?.min_pax}
                  max={packageData?.max_pax}
                  step={1}
                  onChange={handleSliderChange}
                  getAriaValueText={valuetext}
                  valueLabelDisplay="off"
                  marks={marks}
                  sx={{
                    height: 7,
                    color: "#4A6AFE",
                    "& .MuiSlider-thumb": {
                      height: 24,
                      width: 24,
                      backgroundColor: "#fff",
                    },
                    "& .MuiSlider-track": {
                      border: "none",
                    },
                    "& .MuiSlider-rail": {
                      opacity: 0.5,
                      backgroundColor: "gray",
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#4A6AFE",
                      height: 7,
                      width: 3,
                      "&.MuiSlider-markActive": {
                        opacity: 1,
                        backgroundColor: "#fff",
                      },
                    },
                  }}
                />
              </Box>
            </div>
            {/* <div
              className="priceParentDiv"
              style={{ border: "none", padding: "0rem" }}
            >
              <div>
                {selectedPeople >= 50 && (
                  <p className="DiscountedPrice">AED{initialPrice}</p>
                )}
                <p className="ActualPrice">AED{price}</p>
                <p className="personXpax">
                  /person × <span>{selectedPeople}</span>
                </p>
              </div>
              {discountedPrice !== undefined && (
                <p className="TotalPrice">AED{discountedPrice.toLocaleString()}</p>
              )}
            </div> */}
          </div>

          <div>{renderDynamicNumber()}</div>

          {/* *** reserve button *** */}
          <button
            className="ReserveButton"
            onClick={handleContinue}
            disabled={!selectedPeople || !selectedTimeSlot || !date}
            style={{
              cursor:
                !selectedPeople || !selectedTimeSlot || !date
                  ? "not-allowed"
                  : "pointer",
              backgroundColor: themeColor,
              color: themeColor === "#D4A95A" ? "black" : "white",
            }}
          >
            Continue
          </button>

          {/* after slecting people section */}

          {selectedPeople > 0 && (
            <>
              {/* no of people */}
              <div
                className="priceParentDiv2"
                style={{ border: "none", padding: "0rem", marginTop: "1rem" }}
              >
                <div>
                  <div className="ActualPrice">
                    {" "}
                    {formatPrice(initialPrice)}
                  </div>
                  <div className="ActualPrice">
                    × <span className="ActualPrice">{selectedPeople}</span>
                  </div>
                </div>

                {price !== undefined && (
                  <div className="TotalPrice4">
                    {package_currency} {formatPrice(price).toLocaleString()}
                  </div>
                )}
              </div>
              {/* discount */}
              {packageData?.is_coupon !== 1 && moneyToBeSaved > 0 && (
                <div
                  className="total-cost-parent3"
                  style={{ border: "none", padding: "0rem" }}
                >
                  <div className="total-cost-text3">Discount</div>
                  <div className="total-cost3">
                    {" "}
                    - {package_currency}{" "}
                    {formatPrice(moneyToBeSaved).toLocaleString()}
                  </div>
                </div>
              )}

              {/* total price */}
              <div
                style={{
                  border: "none",
                  padding: "1.12rem 0rem 1rem 0rem",
                  width: "100%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="380"
                  height="1"
                  viewBox="0 0 380 1"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.58333 1H0V0H1.58333V1ZM7.91667 1H4.75V0H7.91667V1ZM14.25 1H11.0833V0H14.25V1ZM20.5833 1H17.4167V0H20.5833V1ZM26.9167 1H23.75V0H26.9167V1ZM33.25 1H30.0833V0H33.25V1ZM39.5833 1H36.4167V0H39.5833V1ZM45.9167 1H42.75V0H45.9167V1ZM52.25 1H49.0833V0H52.25V1ZM58.5833 1H55.4167V0H58.5833V1ZM64.9167 1H61.75V0H64.9167V1ZM71.25 1H68.0833V0H71.25V1ZM77.5833 1H74.4167V0H77.5833V1ZM83.9167 1H80.75V0H83.9167V1ZM90.25 1H87.0833V0H90.25V1ZM96.5833 1H93.4167V0H96.5833V1ZM102.917 1H99.75V0H102.917V1ZM109.25 1H106.083V0H109.25V1ZM115.583 1H112.417V0H115.583V1ZM121.917 1H118.75V0H121.917V1ZM128.25 1H125.083V0H128.25V1ZM134.583 1H131.417V0H134.583V1ZM140.917 1H137.75V0H140.917V1ZM147.25 1H144.083V0H147.25V1ZM153.583 1H150.417V0H153.583V1ZM159.917 1H156.75V0H159.917V1ZM166.25 1H163.083V0H166.25V1ZM172.583 1H169.417V0H172.583V1ZM178.917 1H175.75V0H178.917V1ZM185.25 1H182.083V0H185.25V1ZM191.583 1H188.417V0H191.583V1ZM197.917 1H194.75V0H197.917V1ZM204.25 1H201.083V0H204.25V1ZM210.583 1H207.417V0H210.583V1ZM216.917 1H213.75V0H216.917V1ZM223.25 1H220.083V0H223.25V1ZM229.583 1H226.417V0H229.583V1ZM235.917 1H232.75V0H235.917V1ZM242.25 1H239.083V0H242.25V1ZM248.583 1H245.417V0H248.583V1ZM254.917 1H251.75V0H254.917V1ZM261.25 1H258.083V0H261.25V1ZM267.583 1H264.417V0H267.583V1ZM273.917 1H270.75V0H273.917V1ZM280.25 1H277.083V0H280.25V1ZM286.583 1H283.417V0H286.583V1ZM292.917 1H289.75V0H292.917V1ZM299.25 1H296.083V0H299.25V1ZM305.583 1H302.417V0H305.583V1ZM311.917 1H308.75V0H311.917V1ZM318.25 1H315.083V0H318.25V1ZM324.583 1H321.417V0H324.583V1ZM330.917 1H327.75V0H330.917V1ZM337.25 1H334.083V0H337.25V1ZM343.583 1H340.417V0H343.583V1ZM349.917 1H346.75V0H349.917V1ZM356.25 1H353.083V0H356.25V1ZM362.583 1H359.417V0H362.583V1ZM368.917 1H365.75V0H368.917V1ZM375.25 1H372.083V0H375.25V1ZM380 1H378.417V0H380V1Z"
                    fill="#E5E5EA"
                  />
                </svg>
              </div>
              <div
                className="total-cost-parent"
                style={{ border: "none", padding: "0rem" }}
              >
                <div className="total-cost-text">
                  Total <span>(incl.VAT)</span>
                </div>

                {discountedPrice !== undefined && (
                  <div className="total-cost">
                    {" "}
                    {package_currency}{" "}
                    {formatPrice(discountedPrice).toLocaleString()}{" "}
                  </div>
                )}
              </div>
              {moneyToBeSaved > 0 && <div>{renderDynamicSaved()}</div>}
            </>
          )}
        </div>
      </div>
      <Rules packageData={packageData} />

      <div onClick={handleBookAReservation} className="book-bttn">
        <button style={{ backgroundColor: themeColor }}>
          Book a reservation
        </button>
      </div>
    </>
  );
};
export default DateAndTime;
